import React from 'react';
import { X } from 'lucide-react';

function SavePresetDialog({ setIsNaming, newPresetName, setNewPresetName, savePreset }) {
  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
      <div className="bg-white rounded-lg p-6 max-w-md w-full">
        <div className="flex justify-between items-center mb-4">
          <h3 className="text-lg font-semibold">Save Preset</h3>
          <button
            onClick={() => setIsNaming(false)}
            className="text-gray-500 hover:text-gray-700"
          >
            <X size={20} />
          </button>
        </div>
        <input
          type="text"
          value={newPresetName}
          onChange={(e) => setNewPresetName(e.target.value)}
          placeholder="Enter preset name"
          className="w-full p-2 border rounded mb-4"
          autoFocus
        />
        <div className="flex justify-end gap-2">
          <button
            onClick={() => setIsNaming(false)}
            className="px-4 py-2 bg-gray-200 rounded hover:bg-gray-300"
          >
            Cancel
          </button>
          <button
            onClick={savePreset}
            className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
          >
            Save
          </button>
        </div>
      </div>
    </div>
  );
}

export default SavePresetDialog;
