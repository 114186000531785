import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

const GradientSection = forwardRef(({ from, to, children, className = '' }, ref) => {
  const style = to
    ? {
        background: `linear-gradient(to bottom, ${from}, ${to})`
      }
    : {
        background: from
      };

  return (
    <section 
      ref={ref}
      className={`w-full relative ${className}`}
      style={style}
    >
      {children}
    </section>
  );
});

GradientSection.propTypes = {
  from: PropTypes.string.isRequired,
  to: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string
};

GradientSection.displayName = 'GradientSection';

export default GradientSection;
