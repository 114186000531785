import React, { useEffect, useRef, useState } from 'react';
import { useIsMobile, getImagePath } from '../utils/imageUtils';

const SunGradientSection = ({
  sunFadeInStart = 0.75,
  sunFadeInEnd = 0.25,
  sunFadeOutStart = 1,
  sunFadeOutEnd = -0.0,
  flareFadeInStart = 0.65,
  flareFadeInEnd = 0.2,
  flareFadeOutStart = 0.15,
  flareFadeOutEnd = -0.17
}) => {
  const isMobile = useIsMobile();
  const [sunOpacity, setSunOpacity] = useState(0);
  const [flareOpacity, setFlareOpacity] = useState(0);
  const sectionRef = useRef();
  const animationFrameRef = useRef();

  useEffect(() => {
    const handleScroll = () => {
      if (animationFrameRef.current) {
        cancelAnimationFrame(animationFrameRef.current);
      }

      animationFrameRef.current = requestAnimationFrame(() => {
        if (!sectionRef.current) return;

        const rect = sectionRef.current.getBoundingClientRect();
        const windowHeight = window.innerHeight;
        
        // Sun fade ranges
        const sunFadeInStartPx = windowHeight * sunFadeInStart;
        const sunFadeInEndPx = windowHeight * sunFadeInEnd;
        const sunFadeOutStartPx = windowHeight * sunFadeOutStart;
        const sunFadeOutEndPx = windowHeight * sunFadeOutEnd;

        // Flare fade ranges
        const flareFadeInStartPx = windowHeight * flareFadeInStart;
        const flareFadeInEndPx = windowHeight * flareFadeInEnd;
        const flareFadeOutStartPx = windowHeight * flareFadeOutStart;
        const flareFadeOutEndPx = windowHeight * flareFadeOutEnd;

        // Calculate sun opacity
        if (rect.top <= sunFadeInStartPx && rect.top >= sunFadeInEndPx) {
          const fadeRange = sunFadeInStartPx - sunFadeInEndPx;
          const fadeAmount = (rect.top - sunFadeInEndPx) / fadeRange;
          setSunOpacity(1 - fadeAmount);
        } else if (rect.top <= sunFadeOutStartPx && rect.top >= sunFadeOutEndPx) {
          const fadeRange = sunFadeOutStartPx - sunFadeOutEndPx;
          const fadeAmount = (rect.top - sunFadeOutEndPx) / fadeRange;
          setSunOpacity(fadeAmount);
        } else if (rect.top > sunFadeInStartPx || rect.top < sunFadeOutEndPx) {
          setSunOpacity(0);
        } else {
          setSunOpacity(1);
        }

        // Calculate flare opacity
        if (rect.top <= flareFadeInStartPx && rect.top >= flareFadeInEndPx) {
          const fadeRange = flareFadeInStartPx - flareFadeInEndPx;
          const fadeAmount = (rect.top - flareFadeInEndPx) / fadeRange;
          setFlareOpacity(1 - fadeAmount);
        } else if (rect.top <= flareFadeOutStartPx && rect.top >= flareFadeOutEndPx) {
          const fadeRange = flareFadeOutStartPx - flareFadeOutEndPx;
          const fadeAmount = (rect.top - flareFadeOutEndPx) / fadeRange;
          setFlareOpacity(fadeAmount);
        } else if (rect.top > flareFadeInStartPx || rect.top < flareFadeOutEndPx) {
          setFlareOpacity(0);
        } else {
          setFlareOpacity(1);
        }
      });
    };

    window.addEventListener('scroll', handleScroll);
    // Trigger initial opacity calculation
    handleScroll();

    return () => {
      window.removeEventListener('scroll', handleScroll);
      if (animationFrameRef.current) {
        cancelAnimationFrame(animationFrameRef.current);
      }
    };
  }, [sunFadeInStart, sunFadeInEnd, sunFadeOutStart, sunFadeOutEnd, 
      flareFadeInStart, flareFadeInEnd, flareFadeOutStart, flareFadeOutEnd]);

      const sunPath = getImagePath('non_part/Header/Sun', isMobile);
      const lensFlarePath = getImagePath('non_part/Header/Lens Flare', isMobile);
  return (
    <div 
      ref={sectionRef}
      className="relative h-[15vh] w-full select-none pointer-events-none bg-gradient-to-b from-transparent via-red-500/20 via-orange-500/20 via-yellow-500/20 via-green-500/20 via-blue-500/20 via-indigo-500/20 via-purple-500/20 to-transparent"    >
      <img 
        src={sunPath}
        alt="Sun" 
        className="absolute object-contain h-[100vh] w-auto -translate-y-1/4 left-1/2 -translate-x-1/2 transition-opacity duration-100"
        style={{ opacity: sunOpacity, zIndex:10 }}
      />
      <img 
        src={lensFlarePath}
        alt="Lens Flare" 
        className="absolute object-contain h-[100vh] w-auto -translate-y-1/4 left-1/2 -translate-x-1/2 transition-opacity duration-100"
        style={{ opacity: flareOpacity, zIndex:10 }}
      />
    </div>
  );
};

export default SunGradientSection;
