import React, { forwardRef } from 'react';
import SVGSocials from './Header/SVGSocials';
import RugmanSVG from './Header/SVGRugman';

const Footer = forwardRef((props, ref) => {
  return (
    <footer ref={ref} className='w-full'>
      <div className="w-full mx-auto flex flex-col items-center gap-2 mb-4">
        <h3 className="text-white text-xl font-semibold mansalva-text select-none" style={{pointerEvents: 'none'}}>
          Connect with Rugman
        </h3>
        <div className="w-full overflow-x-clip">
          <RugmanSVG className="w-full max-h-[100px] px-8 select-none" />
        </div>
        <div className="w-full">
          <SVGSocials className="w-full max-h-[100px]" viewBox="0 150 400 70" />
        </div>
        
        <div className="w-full px-4 mt-8 md:px-8 lg:px-16 space-y-3 max-w-screen-2xl mx-auto">
          {/* Header */}
          <p className="text-center text-white font-bold text-[25px] md:text-[22px] select-none">
            © 2024 RUGMAN on Hyperliquid. All rights reserved.
          </p>
          
          {/* Subheader */}
          <p className="text-center text-white/90 text-[15px] md:text-[14px] select-none">
            This platform is provided strictly for entertainment purposes. The content, including meme generation and token trading, should not be construed as financial advice, investment recommendation, or solicitation.
          </p>
          
          {/* Body */}
          <p className="text-center text-white/80 text-[10px] md:text-[9px] leading-relaxed select-none">
            All user-generated memes and content remain the intellectual property of their respective creators. Users grant the platform a non-exclusive, worldwide license to display, share, and promote user-generated content. Users are solely responsible for the content they create. Cryptocurrency and meme tokens can involve significant financial risk. Users acknowledge and accept that token valuations can be extremely volatile, investments may result in total loss of funds, and past performance does not guarantee future results. The platform is not responsible for user trading decisions or the performance or liquidity of the token. Please comply with your local, state, and federal regulations. The platform reserves the right to modify, suspend, or discontinue services. This platform is not available to users in jurisdictions where such services are prohibited.
          </p>
          
          {/* Final Statement */}
          <p className="text-center text-white/80 text-[10px] md:text-[9px] font-bold select-none">
            BY USING THIS PLATFORM, YOU ACKNOWLEDGE THAT YOU HAVE READ, UNDERSTOOD, AND AGREE TO THESE TERMS.
          </p>
        </div>
      </div>
    </footer>
  );
});

export default Footer;
