import React from 'react';
import SceneLayer from './SceneLayer';
import { useIsMobile, getImagePath } from '../utils/imageUtils';

const Fields = () => {
  const isMobile = useIsMobile();
  
  // Pre-calculate all image paths
  const bottomCloudsPath = getImagePath('non_part/Fields/BottomClouds_right', isMobile);
  const fieldsPath = getImagePath('non_part/Fields/Fields', isMobile);
  const treePath = getImagePath('non_part/Fields/Tree', isMobile);
  const rugmanPath = getImagePath('non_part/Fields/Rugman_bottom_cropped', isMobile);
  const flowerPath = getImagePath('non_part/Fields/Flower', isMobile);

  return (
    <section className="relative w-full" style={{pointerEvents: 'none'}}>
      <div className="relative w-full">
        {/* Clouds */}
        <SceneLayer
          imageSrc={bottomCloudsPath}
          zIndex={2}
          className="animate-float-x-slow"
        />
        
        {/* Background field */}
        <div className='overflow-hidden'>
          <div className="absolute -left-[4vw] -right-[4vw] md:-left-[100px] md:-right-[100px]">
            <SceneLayer
              imageSrc={fieldsPath}
              zIndex={1}
              className="animate-wave-small !relative"
            />
          </div>
        </div>
        
        {/* Tree behind Rugman */}
        <div className="absolute inset-0 overflow-hidden">
          <div className="absolute -left-[20vw] -right-[20vw] md:-left-[392px] md:-right-[392px]">
            <SceneLayer
              imageSrc={treePath}
              zIndex={2}
              className="animate-wave-slow !relative"
            />
          </div>
        </div>
        
        {/* Rugman character */}
        <div className='overflow-hidden'>
          <SceneLayer
            imageSrc={rugmanPath}
            zIndex={3}
          />
        </div>

        {/* Flower */}
        <div className='overflow-hidden'>
          <SceneLayer
            imageSrc={flowerPath}
            zIndex={4}
            parallax={true}
            parallaxSpeed={window.innerWidth <= 768 ? 1.25 : 0.3} // Kept inline check for parallax speed
            className='origin-bottom animate-tilt-normal'
          />
        </div>
      </div>
    </section>
  );
}

export default Fields;
