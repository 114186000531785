import React, { useState, useRef, useEffect, forwardRef  } from 'react';

import PartsSelection from './PartsSelection';
import TransformControls from './TransformControls';
import EnhancedTextControls from './EnhancedTextControls';
import QuickActionsBar from './QuickActionsBar';
import DeleteConfirmModal from './DeleteConfirmModal';
import SavePresetDialog from './SavePresetDialog';
import PresetsBar from './PresetsBar';
import ImageControlsPanel from './ImageControlsPanel';

const CharacterCustomizer = forwardRef((props, ref) => {
  const ASPECT_RATIO = 9/16;
  const [downloadWidth, setDownloadWidth] = useState(1250);
  const DOWNLOAD_HEIGHT = downloadWidth * ASPECT_RATIO;
  
  const [selectedParts, setSelectedParts] = useState({
    background: null, // New background layer
    custom_image: null, // Second background layer
    Rugman_Logo: null,
    Hyperliquid_Logo: null,
    body: null,
    face: null,
    miscellaneous: null,
    hat: null,
    text1: { text: '' },
    text2: { text: '' },
    hypurr: null,
    meme_image: null, // Second background layer
  });

  const defaultTransforms = {
    background: { x: 0, y: 0, scale: 1.02, rotation: 0, zIndex: 1, opacity: 1 },
    custom_image: { x: 0, y: 0, scale: 1, rotation: 0, zIndex: 2, opacity: 1 },
    Rugman_Logo: { x: -0.38, y: -0.42, scale: 0.2, rotation: 0, zIndex: 6, opacity: 1 },
    Hyperliquid_Logo: { x: 0.38, y: 0.42, scale: 0.2, rotation: 0, zIndex: 6, opacity: 1 },
    body: { x: 0, y: 0, scale: 1, rotation: 0, zIndex: 3, opacity: 1 },
    face: { x: 0, y: -0.19, scale: 0.35, rotation: 0, zIndex: 4, opacity: 1 },
    miscellaneous: { x: 0.3, y: -0.25, scale: 0.25, rotation: 0, zIndex: 4, opacity: 1 },
    hat: { x: -0.02, y: -0.35, scale: 0.25, rotation: 0, zIndex: 4, opacity: 1 },
    text1: { x: 0, y: -0.8, scale: 1, rotation: 0, zIndex: 7, opacity: 1 },
    text2: { x: 0, y: 0.8, scale: 1, rotation: 0, zIndex: 7, opacity: 1 },
    hypurr: { x: 0.3, y: 0, scale: 0.5, rotation: 0, zIndex: 6, opacity: 1 },
    meme_image: { x: 0, y: 0, scale: 1, rotation: 0, zIndex: 1, opacity: 1 },
  };
  const [transforms, setTransforms] = useState(defaultTransforms);
  const [containerWidth, setContainerWidth] = useState(0);
  const [selectedPartForEdit, setSelectedPartForEdit] = useState(null);
  const [isDragging, setIsDragging] = useState(false);
  const dragStartPos = useRef({ x: 0, y: 0 });
  const initialTransform = useRef({ x: 0, y: 0 });
  const [customBackgrounds, setCustomBackgrounds] = useState([]);
  const [uploadError, setUploadError] = useState('');
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [history, setHistory] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(-1);
  const [memes, setMemes] = useState([]);
  const [isLoadingMemes, setIsLoadingMemes] = useState(false);
  const [shouldCropToSquare, setShouldCropToSquare] = useState(false);
  
  const canvasRef = useRef(null);
  const previewRef = useRef(null);
  const containerRef = useRef(null);
  const [loadedImages, setLoadedImages] = useState({});

  // State for preset name input
  const [isNaming, setIsNaming] = useState(false);
  const [newPresetName, setNewPresetName] = useState('');

  const toggleCropToSquare = () => {
    setShouldCropToSquare(prev => !prev);
  };
  

  const fetchMemes = async () => {
    setIsLoadingMemes(true);
    try {
      const response = await fetch('https://api.imgflip.com/get_memes');
      const data = await response.json();
      if (data.success) {
        // Shuffle the memes array to get random memes
        const shuffledMemes = [...data.data.memes]
          .sort(() => Math.random() - 0.5)
          .slice(0, 9); // Get first 9 memes after shuffling
        setMemes(shuffledMemes);
      }
    } catch (error) {
      console.error('Error fetching memes:', error);
    }
    setIsLoadingMemes(false);
  };

  useEffect(() => {
    fetchMemes();
  }, []);

  const saveToHistory = (newSelectedParts, newTransforms) => {
    const newHistory = history.slice(0, currentIndex + 1);
    newHistory.push({
      selectedParts: {...selectedParts},
      transforms: {...transforms}
    });
    
    setHistory(newHistory);
    setCurrentIndex(currentIndex + 1);
    
    if (newSelectedParts) setSelectedParts(newSelectedParts);
    if (newTransforms) setTransforms(newTransforms);
  };

  const resetTransforms = () => {
    setTransforms(defaultTransforms);
  };

  const [presets, setPresets] = useState(() => {
    try {
      const savedPresets = localStorage.getItem('characterCustomizerPresets');
      return savedPresets ? JSON.parse(savedPresets) : [];
    } catch (error) {
      console.error('Error loading presets:', error);
      return [];
    }
  });

  const [textStyles, setTextStyles] = useState({
    text1: {
      color: '#FFFFFF',
      font: 'Arial',
      hasShadow: false,
      hasOutline: false,
    },
    text2: {
      color: '#FFFFFF',
      font: 'Arial',
      hasShadow: false,
      hasOutline: false,
    }
  });

  const fonts = [
    'Arial',
    'Times New Roman',
    'Courier New',
    'Georgia',
    'Comic Sans MS'
  ];

  useEffect(() => {
    try {
      localStorage.setItem('characterCustomizerPresets', JSON.stringify(presets));
    } catch (error) {
      console.error('Error saving presets:', error);
    }
  }, [presets]);

  const initiatePresetSave = () => {
    setNewPresetName(`Preset ${presets.length + 1}`);
    setIsNaming(true);
  };

  const savePreset = () => {
    if (!newPresetName.trim()) return;
    
    const existingPresetIndex = presets.findIndex(p => p.name === newPresetName);
    const preset = {
      id: Date.now(),
      name: newPresetName,
      selectedParts,
      transforms,
      textStyles
    };
  
    if (existingPresetIndex !== -1) {
      // Show confirmation dialog for overwriting
      if (window.confirm(`A preset with name "${newPresetName}" already exists. Do you want to replace it?`)) {
        const updatedPresets = [...presets];
        updatedPresets[existingPresetIndex] = preset;
        setPresets(updatedPresets);
      } else {
        // User canceled overwrite
        return;
      }
    } else {
      // No existing preset with this name, add new one
      setPresets(prev => [...prev, preset]);
    }
    
    setIsNaming(false);
    setNewPresetName('');
  };

  const clearAllPresets = () => {
    setPresets([]);
    try {
      localStorage.removeItem('characterCustomizerPresets');
    } catch (error) {
      console.error('Error clearing presets:', error);
    }
  };

  const loadPreset = (preset) => {
    setSelectedParts(preset.selectedParts);
    setTransforms(preset.transforms);
    setTextStyles(preset.textStyles);
  };

  const randomize = () => {
    const newParts = {};
    Object.entries(parts).forEach(([category, items]) => {
      if (items.length && !category.includes('custom') && !category.includes('meme') && !category.includes('hypurr') && !category.includes('miscellaneous') && !category.includes('logo')) {
        const randomIndex = Math.floor(Math.random() * items.length);
        newParts[category] = items[randomIndex];
      }
    });

    const newSelectedParts = {
      ...selectedParts,
      ...newParts
    };
    
    saveToHistory(newSelectedParts, null);
  };

  useEffect(() => {
    if (!containerRef.current) return;

    const updateContainerWidth = () => {
      const previewWidth = previewRef.current?.clientWidth - 32;
      const maxWidth = Math.min(previewWidth || 0, 600);
      setContainerWidth(maxWidth);
    };

    const resizeObserver = new ResizeObserver(updateContainerWidth);
    resizeObserver.observe(previewRef.current);
    updateContainerWidth();

    return () => resizeObserver.disconnect();
  }, []);

  const parts = {
    Rugman_Logo: Array.from({ length: 1 }, (_, i) => `/assets/Rugman_Logo/logo${i + 1}.webp`),
    Hyperliquid_Logo: Array.from({ length: 3 }, (_, i) => `/assets/Hyperliquid_Logo/logo${i + 1}.webp`),
    body: Array.from({ length: 16 }, (_, i) => `/assets/body/body${i + 1}.webp`),
    face: Array.from({ length: 36 }, (_, i) => `/assets/face/face${i + 1}.webp`),
    miscellaneous: Array.from({ length: 10 }, (_, i) => `/assets/miscellaneous/misc${i + 1}.webp`),
    hat: Array.from({ length: 14 }, (_, i) => `/assets/hat/hat${i + 1}.webp`),
    hypurr: Array.from({ length: 23 }, (_, i) => `/assets/hypurr/hypurr${i + 1}.webp`),
    background: [
      ...Array.from({ length: 23 }, (_, i) => `/assets/background/background${i + 1}.webp`),
    ],
    custom_image: [
      ...customBackgrounds,
    ],
    meme_image: memes.map(meme => meme.url),
  };

  // Updated handleTextChange
  const handleTextChange = (textKey) => (e) => {
    setSelectedParts(prev => ({
      ...prev,
      [textKey]: { ...prev[textKey], text: e.target.value }
    }));
    setSelectedPartForEdit(textKey);
  };

  useEffect(() => {
    const loadImage = (url, part) => {
      return new Promise((resolve, reject) => {
        const img = new Image();
        img.crossOrigin = "anonymous";  // To handle potential CORS issues
        img.onload = () => {
          // Create a canvas to adjust the aspect ratio
          const aspectRatio = 16 / 9;
          const canvas = document.createElement('canvas');
          let canvasWidth, canvasHeight;
    
          // Calculate new canvas size to match the 16:9 aspect ratio
          if (img.width / img.height > aspectRatio) {
            canvasWidth = img.width;
            canvasHeight = img.width / aspectRatio;
          } else {
            canvasHeight = img.height;
            canvasWidth = img.height * aspectRatio;
          }
    
          canvas.width = canvasWidth;
          canvas.height = canvasHeight;
          const ctx = canvas.getContext('2d');
    
          // Fill canvas with a transparent background
          ctx.fillStyle = 'rgba(0, 0, 0, 0)';
          ctx.fillRect(0, 0, canvasWidth, canvasHeight);
    
          // Draw the image centered on the canvas
          const offsetX = (canvasWidth - img.width) / 2;
          const offsetY = (canvasHeight - img.height) / 2;
          ctx.drawImage(img, offsetX, offsetY, img.width, img.height);
    
          // Create a new Image object from the canvas data URL
          const processedImg = new Image();
          processedImg.crossOrigin = "anonymous"; // Ensure CORS for later use
          processedImg.onload = () => {
            loadedImages[part] = processedImg;
            resolve(processedImg);
          };
          processedImg.onerror = reject;
          processedImg.src = canvas.toDataURL('image/webp');
        };
        img.onerror = reject;
        img.src = url;
      });
    };

    const loadSelectedParts = async () => {
      const newLoadedImages = {};
      for (const [part, value] of Object.entries(selectedParts)) {
        if (value && !part.includes('text')) {
          try {
            newLoadedImages[part] = await loadImage(value);
          } catch (error) {
            console.error(`Failed to load ${part} image:`, error);
          }
        }
      }
      setLoadedImages(newLoadedImages);
    };

    loadSelectedParts();
  }, [selectedParts]);

  const handleDragStart = (e) => {
    if (e.type === 'mousedown' || e.type === 'touchstart') {
      if (selectedPartForEdit) {
        setIsDragging(true);
        const pos = getEventPosition(e);
        dragStartPos.current = pos;
        initialTransform.current = {
          x: transforms[selectedPartForEdit].x,
          y: transforms[selectedPartForEdit].y
        };
        e.preventDefault();
      }
    }
  };

  const handleDragMove = (e) => {
    if (!isDragging || !selectedPartForEdit || !containerRef.current) return;

    const pos = getEventPosition(e);
    const containerRect = containerRef.current.getBoundingClientRect();
    
    const dx = (pos.x - dragStartPos.current.x) / containerRect.width;
    const dy = (pos.y - dragStartPos.current.y) / containerRect.height;

    const scale = selectedPartForEdit.includes('text') ? transforms[selectedPartForEdit].scale : 1;
    
    const newTransforms = {
      ...transforms,
      [selectedPartForEdit]: {
        ...transforms[selectedPartForEdit],
        x: initialTransform.current.x + (dx / scale),
        y: initialTransform.current.y + (dy / scale)
      }
    };
    
    setTransforms(newTransforms);
  };

  const handleDragEnd = () => {
    if (isDragging && selectedPartForEdit) {
      // Save the final position to history only if we were actually dragging
      const finalTransforms = {
        ...transforms,
        [selectedPartForEdit]: {
          ...transforms[selectedPartForEdit],
          x: transforms[selectedPartForEdit].x,
          y: transforms[selectedPartForEdit].y
        }
      };
      
      // Create new history entry
      const newHistory = history.slice(0, currentIndex + 1);
      newHistory.push({
        selectedParts: {...selectedParts},
        transforms: finalTransforms
      });
      
      setHistory(newHistory);
      setCurrentIndex(currentIndex + 1);
    }
    setIsDragging(false);
  };

  const getEventPosition = (e) => {
    if (e.touches) {
      return {
        x: e.touches[0].clientX,
        y: e.touches[0].clientY
      };
    }
    return {
      x: e.clientX,
      y: e.clientY
    };
  };

  useEffect(() => {
    const handleMouseMove = (e) => handleDragMove(e);
    const handleTouchMove = (e) => handleDragMove(e);
    const handleMouseUp = () => handleDragEnd();
    const handleTouchEnd = () => handleDragEnd();

    if (isDragging) {
      window.addEventListener('mousemove', handleMouseMove);
      window.addEventListener('touchmove', handleTouchMove);
      window.addEventListener('mouseup', handleMouseUp);
      window.addEventListener('touchend', handleTouchEnd);
    }

    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('touchmove', handleTouchMove);
      window.removeEventListener('mouseup', handleMouseUp);
      window.removeEventListener('touchend', handleTouchEnd);
    };
  }, [isDragging]);

  const handlePartSelection = (category, item) => {
    const newSelectedParts = {
      ...selectedParts,
      [category]: item
    };
    
    saveToHistory(newSelectedParts, null);
    setSelectedPartForEdit(category);
  };

  const handleDeletePart = (category) => {
    const newSelectedParts = {
      ...selectedParts,
      [category]: null
    };
    
    saveToHistory(newSelectedParts, null);
    setSelectedPartForEdit(null);
  };

  const handleUndo = () => {
    if (currentIndex > 0) {
      const previousState = history[currentIndex - 1];
      setSelectedParts(previousState.selectedParts);
      setTransforms(previousState.transforms);
      setCurrentIndex(currentIndex - 1);
    }
  };
  
  const renderPartImage = (part) => {
    const transform = transforms[part];
    
    if (part.includes('text')) {
      const text = selectedParts[part]?.text || '';
      const style = textStyles[part];
      if (!text) return null;
      
      const textStyle = {
        position: 'absolute',
        left: '50%',
        top: '50%',
        transform: `
          translate(-50%, -50%)
          translate(${transforms[part].x * containerWidth / 2}px, ${transforms[part].y * containerWidth * ASPECT_RATIO / 2}px)
          rotate(${transforms[part].rotation}deg)
          scale(${transforms[part].scale * ASPECT_RATIO})
        `,
        opacity: transform.opacity,
        zIndex: transforms[part].zIndex,
        color: style.color,
        fontFamily: style.font,
        fontSize: '1.5rem',
        fontWeight: 'bold',
        touchAction: 'none',
        whiteSpace: 'pre-wrap', // Change from 'nowrap' to 'pre-wrap'
        maxWidth: '80%', // Add this to control text wrapping width
        userSelect: 'none',
        textShadow: style.hasShadow ? '2px 2px 4px rgba(0,0,0,0.5)' : 'none',
        WebkitTextStroke: style.hasOutline ? '1px black' : '0px black',
      };
      
      return (
        <div
          style={textStyle}
          className={`text-center ${selectedPartForEdit === part ? 'ring-2 ring-blue-500' : ''}`}
          onMouseDown={(e) => {
            e.preventDefault();
            handleDragStart(e);
          }}
          onClick={() => setSelectedPartForEdit(part)}
          onTouchStart={(e) => handleDragStart(e)}
        >
          {text}
        </div>
      );
    }

    if (!selectedParts[part]) return null;
    
    return (
      <img
        crossOrigin="anonymous"
        src={selectedParts[part]}
        alt={part}
        className={`absolute inset-0 w-full h-full object-contain transition-transform cursor-move
          ${selectedPartForEdit === part ? 'ring-2 ring-blue-500' : ''}`}
        style={{
          transform: `
            translate(${transform.x * 100}%, ${transform.y * 100}%)
            rotate(${transform.rotation}deg)
            scale(${transform.scale})
          `,
          opacity: transform.opacity,
          zIndex: transform.zIndex,
          touchAction: 'none',
        }}
        onClick={() => setSelectedPartForEdit(part)}
        onMouseDown={(e) => handleDragStart(e)}
        onTouchStart={(e) => handleDragStart(e)}
      />
    );
  };

  const updateTransform = (property, value) => {
    if (!selectedPartForEdit) return;

    const newTransforms = {
      ...transforms,
      [selectedPartForEdit]: {
        ...transforms[selectedPartForEdit],
        [property]: value
      }
    };
    
    saveToHistory(null, newTransforms);
  };
  
  const getTextLines = (ctx, text, maxWidth) => {
    const words = text.split(' ');
    const lines = [];
    let currentLine = words[0];
  
    for (let i = 1; i < words.length; i++) {
      const word = words[i];
      const width = ctx.measureText(currentLine + ' ' + word).width;
      if (width < maxWidth) {
        currentLine += ' ' + word;
      } else {
        lines.push(currentLine);
        currentLine = word;
      }
    }
    lines.push(currentLine);
    return lines;
  };

  const downloadCharacter = () => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');

    // Obtain original dimensions programmatically
    const originalHeight = DOWNLOAD_HEIGHT; // Set to your original height value
    canvas.width = downloadWidth;
    canvas.height = originalHeight;

    ctx.clearRect(0, 0, canvas.width, canvas.height);

    // Add meme_image to the sortedParts array
    const sortedParts = ['Rugman_Logo', 'Hyperliquid_Logo', 'hypurr', 'background', 'custom_image', 'meme_image', 'body', 'face', 'miscellaneous', 'hat', 'text1', 'text2']
      .sort((a, b) => transforms[a].zIndex - transforms[b].zIndex);

    sortedParts.forEach(part => {
      const transform = transforms[part];
      ctx.save();
      
      // Set global alpha for opacity
      ctx.globalAlpha = transform.opacity;

      if (part.includes('text')) {
        const text = selectedParts[part]?.text || '';
        const style = textStyles[part];
        
        if (text) {
          // Adjust font size based on download width
          const baseFontSize = downloadWidth * 0.025; // Adjust this multiplier to change relative text size
          const scaledFontSize = Math.round(baseFontSize * transform.scale);
          
          ctx.font = `bold ${scaledFontSize}px ${style.font}`;
          ctx.textAlign = 'center';
          ctx.textBaseline = 'middle';
      
          const centerX = canvas.width / 2;
          const centerY = canvas.height / 2;
      
          const offsetX = transform.x * (canvas.width / 2);
          const offsetY = transform.y * (canvas.height / 2);
      
          const textX = centerX + offsetX;
          const textY = centerY + offsetY;
      
          // Split the text into lines based on explicit line breaks
          const textLines = text.split('\n');
          const lineHeight = scaledFontSize * 1.2; // Adjust line spacing as needed
      
          ctx.save();
          
          // Apply rotation if any
          if (transform.rotation !== 0) {
            ctx.translate(textX, textY);
            ctx.rotate(transform.rotation * Math.PI / 180);
            ctx.translate(-textX, -textY);
          }
      
          // Calculate total height of text block
          const totalHeight = lineHeight * textLines.length;
          const startY = textY - (totalHeight / 2) + (lineHeight / 2);
      
          textLines.forEach((line, index) => {
            const lineY = startY + (index * lineHeight);
      
            // Apply shadow if enabled
            if (style.hasShadow) {
              ctx.shadowColor = 'rgba(0, 0, 0, 0.5)';
              ctx.shadowBlur = scaledFontSize / 8;
              ctx.shadowOffsetX = scaledFontSize / 16;
              ctx.shadowOffsetY = scaledFontSize / 16;
            } else {
              ctx.shadowColor = 'transparent';
              ctx.shadowBlur = 0;
              ctx.shadowOffsetX = 0;
              ctx.shadowOffsetY = 0;
            }
      
            // Draw outline if enabled
            if (style.hasOutline) {
              ctx.strokeStyle = 'black';
              ctx.lineWidth = scaledFontSize / 16;
              ctx.strokeText(line, textX, lineY);
            }
      
            // Draw the text
            ctx.fillStyle = style.color;
            ctx.fillText(line, textX, lineY);
          });
      
          ctx.restore();
        }
      }
       else if (loadedImages[part]) {
        const centerX = canvas.width / 2;
        const centerY = canvas.height / 2;

        ctx.translate(centerX + transform.x * canvas.width, centerY + transform.y * canvas.height);
        ctx.rotate(transform.rotation * Math.PI / 180);
        ctx.scale(transform.scale, transform.scale);

        ctx.drawImage(
          loadedImages[part],
          -canvas.width / 2,
          -canvas.height / 2,
          canvas.width,
          canvas.height
        );
      }

      ctx.restore();
    });

    if (shouldCropToSquare) {
      // Calculate the size of the square crop
      const cropSize = Math.min(canvas.width, canvas.height);
  
      // Create a new canvas for the cropped image
      const croppedCanvas = document.createElement('canvas');
      croppedCanvas.width = cropSize;
      croppedCanvas.height = cropSize;
      const croppedCtx = croppedCanvas.getContext('2d');
  
      // Calculate the x and y offsets to center the image
      const offsetX = (canvas.width - cropSize) / 2;
      const offsetY = (canvas.height - cropSize) / 2;
  
      // Draw the cropped image to the new canvas
      croppedCtx.drawImage(
        canvas,
        offsetX,
        offsetY,
        cropSize,
        cropSize,
        0,
        0,
        cropSize,
        cropSize
      );
  
      // Create the download link for the cropped image
      const link = document.createElement('a');
      link.download = 'rugman-squared.png';
      link.href = croppedCanvas.toDataURL('image/png', 1.0);
      link.click();
    } else {
      // Create the download link for the original image
      const link = document.createElement('a');
      link.download = 'rugman.png';
      link.href = canvas.toDataURL('image/png', 1.0);
      link.click();
    }
  };
  

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (!file) return;
  
    // Check file type
    if (!file.type.startsWith('image/')) {
      setUploadError('Please upload an image file');
      return;
    }
  
    // Check file size (5MB limit)
    if (file.size > 5 * 1024 * 1024) {
      setUploadError('Image must be smaller than 5MB');
      return;
    }
  
    const reader = new FileReader();
    reader.onload = (e) => {
      const img = new Image();
      img.src = e.target.result;
      img.onload = () => {
        const aspectRatio = 16 / 9;
        const canvas = document.createElement('canvas');
        let canvasWidth, canvasHeight;
  
        // Calculate new canvas size to match the 16:9 aspect ratio
        if (img.width / img.height > aspectRatio) {
          canvasWidth = img.width;
          canvasHeight = img.width / aspectRatio;
        } else {
          canvasHeight = img.height;
          canvasWidth = img.height * aspectRatio;
        }
  
        canvas.width = canvasWidth;
        canvas.height = canvasHeight;
        const ctx = canvas.getContext('2d');
  
        // Fill canvas with transparent background
        ctx.fillStyle = 'rgba(0, 0, 0, 0)';
        ctx.fillRect(0, 0, canvasWidth, canvasHeight);
  
        // Draw the image centered on the canvas
        const offsetX = (canvasWidth - img.width) / 2;
        const offsetY = (canvasHeight - img.height) / 2;
        ctx.drawImage(img, offsetX, offsetY, img.width, img.height);
  
        // Convert the canvas to a data URL
        const newBackground = canvas.toDataURL('image/png');
        setCustomBackgrounds((prev) => [...prev, newBackground]);
        // Automatically select the newly uploaded background
        handlePartSelection('custom_image', newBackground);
        setUploadError('');
      };
    };
    reader.readAsDataURL(file);
  };
  
  
  const hasSelectedParts = Object.values(selectedParts).some(part => part !== null);

  return (
    <div ref={ref} className="p-6 mx-auto max-w-[1800px]">
      <QuickActionsBar
        randomize={randomize}
        handleUndo={handleUndo}
        currentIndex={currentIndex}
        initiatePresetSave={initiatePresetSave}
        setShowDeleteConfirm={setShowDeleteConfirm}
        presets={presets}
      />
      
      {showDeleteConfirm && (
        <DeleteConfirmModal
          setShowDeleteConfirm={setShowDeleteConfirm}
          clearAllPresets={clearAllPresets}
        />
      )}
      
      {isNaming && (
        <SavePresetDialog
          setIsNaming={setIsNaming}
          newPresetName={newPresetName}
          setNewPresetName={setNewPresetName}
          savePreset={savePreset}
        />
      )}
      
      {presets.length > 0 && (
        <PresetsBar
          presets={presets}
          loadPreset={loadPreset}
        />
      )}

      <div className="grid grid-cols-1 md:grid-cols-5 gap-8 mt-4">
        {/* Preview Area - Takes up 2 columns */}
        <div className="md:col-span-2">
          <div
            ref={previewRef}
            className="bg-blue-100 p-4 md:p-8 rounded-lg flex items-center justify-center preview"
          >
            <div
              ref={containerRef}
              className="relative border-2 border-dashed border-blue-300"
              style={{
                width: `${containerWidth}px`,
                height: `${containerWidth * ASPECT_RATIO}px`,
                maxWidth: '100%'
              }}
            >
              {renderPartImage('background')}
              {renderPartImage('custom_image')}
              {renderPartImage('Rugman_Logo')}
              {renderPartImage('Hyperliquid_Logo')}
              {renderPartImage('body')}
              {renderPartImage('face')}
              {renderPartImage('miscellaneous')}
              {renderPartImage('hat')}
              {renderPartImage('text1')}
              {renderPartImage('text2')}
              {renderPartImage('hypurr')}
              {renderPartImage('meme_image')}
              {shouldCropToSquare && (
                <div
                  className="absolute border-2 border-dashed border-blue-500 rounded"
                  style={{
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: `${containerWidth * ASPECT_RATIO}px`,
                    height: `${containerWidth * ASPECT_RATIO}px`,
                    zIndex: 10,
                  }}
                />
              )}
            </div>
          </div>

          <TransformControls
            selectedPartForEdit={selectedPartForEdit}
            transforms={transforms}
            updateTransform={updateTransform}
            handleDeletePart={handleDeletePart}
          />

          <EnhancedTextControls
            selectedPartForEdit={selectedPartForEdit}
            textStyles={textStyles}
            setTextStyles={setTextStyles}
            fonts={fonts}
          />

          <ImageControlsPanel
            downloadWidth={downloadWidth}
            setDownloadWidth={setDownloadWidth}
            resetTransforms={resetTransforms}
            downloadCharacter={downloadCharacter}
            hasSelectedParts={hasSelectedParts}
            setShouldCropToSquare={setShouldCropToSquare}
            toggleCropToSquare={toggleCropToSquare}
          />

          <canvas ref={canvasRef} className="hidden" />
        </div>

        {/* Parts Selection - Takes up 3 columns */}
        <div className="md:col-span-3">
          <PartsSelection
            parts={parts}
            selectedParts={selectedParts}
            handlePartSelection={handlePartSelection}
            handleTextChange={handleTextChange}
            handleImageUpload={handleImageUpload}
            uploadError={uploadError}
            isLoadingMemes={isLoadingMemes}
            fetchMemes={fetchMemes}
            memes={memes}
          />
        </div>
      </div>
    </div>
  );
});

export default CharacterCustomizer;