import React from 'react';

const GeneratorGuide = () => {
  const steps = [
    { number: "1", text: "choose your background" },
    { number: "2", text: "pick your RUGMAN face or body combination" },
    { number: "3", text: "generate and enjoy!" }
  ];

  return (
    <section className="px-6 py-12 max-w-2xl mx-auto">
      {/* Title Section */}
      <div className="mb-8">
        <div className="relative flex justify-center mb-4">
          <img 
            src="assets/non_part/Rugman_Face_logo.webp"
            alt="Generator Logo" 
            className="absolute left-0 w-10 h-10 object-contain"
          />
          <h1 className="meet-rugman-text text-white text-4xl md:text-5xl">
            The Generator
          </h1>
        </div>
        <img 
          src="assets/non_part/GeneratorGuide/RugmanYay.webp"
          alt="Rugman Character"
          className="mx-auto w-96"
        />
      </div>

      {/* Welcome Message */}
      <div className="bg-gray-800/50 rounded-lg p-6 text-center mb-8">
        <h2 className="meet-rugman-text text-white text-xl mb-2">
          Welcome to RUGMAN's Universe!
        </h2>
        <p className="meet-rugman-text text-white text-lg">
          Please enjoy our beta test of the RUGMAN pfp and meme generator
        </p>
      </div>

      {/* Steps Section */}
      <div className="bg-gray-800/50 rounded-lg p-6 relative">
        <div className="flex flex-col gap-4">
          {steps.map((step, index) => (
            <div key={index} className="flex items-center gap-2">
              <span className="meet-rugman-text text-white font-bold whitespace-nowrap">
                Step {step.number}:
              </span>
              <span className="meet-rugman-text text-white">
                {step.text}
              </span>
            </div>
          ))}
        </div>
        
        {/* Hand Image */}
        <img 
          src="assets/non_part/GeneratorGuide/Hand.webp"
          alt="Hand pointer"
          className="absolute -right-4 top-1/2 transform -translate-y-1/2 w-16 md:w-24"
        />
      </div>

      {/* Note */}
      <p className="meet-rugman-text text-white text-sm text-center mt-4 italic">
        (note you can click Enable square cropping for pfps and social media posting as preferred)
      </p>
    </section>
  );
};

export default GeneratorGuide;
