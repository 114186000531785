import React, { useState, useRef, forwardRef } from 'react';
import ParticleSystem from './ParticleSystem';
import Logo from './Logo';
import ScrollIndicator from './ScrollIndicator';
import BackgroundVideo from './BackgroundVideo';
import { useIsMobile, getImagePath } from '../../utils/imageUtils';

const LandingHeader = forwardRef(({ scrollToAfterScrollingSequence }, ref) => {
  const isMobile = useIsMobile();
  const [isBackgroundLoaded, setIsBackgroundLoaded] = useState(false);
  const particleSystemRef = useRef();
  
  const is_mobile = window.innerWidth < 768;

  const handleMouseMove = (e) => {
    if (particleSystemRef.current) {
      particleSystemRef.current.handleInteraction(e);
    }
  };

  const handleMouseEnter = () => {
    if (particleSystemRef.current) {
      particleSystemRef.current.setIsInteracting(true);
    }
  };

  const handleMouseLeave = () => {
    if (particleSystemRef.current) {
      particleSystemRef.current.setIsInteracting(false);
    }
  };

  const handleTouchStart = (e) => {
    if (particleSystemRef.current) {
      particleSystemRef.current.setIsInteracting(true);
      particleSystemRef.current.handleInteraction(e);
    }
  };

  const handleTouchMove = (e) => {
    if (particleSystemRef.current) {
      particleSystemRef.current.handleInteraction(e);
    }
  };

  const handleTouchEnd = () => {
    if (particleSystemRef.current) {
      particleSystemRef.current.setIsInteracting(false);
    }
  };

  const handleClick = (e) => {
    if (particleSystemRef.current) {
      particleSystemRef.current.handleClick(e);
    }
  };

  const handleBackgroundLoad = () => {
    // Add a slight delay to ensure smooth transition
    setTimeout(() => {
      setIsBackgroundLoaded(true);
      // Add opacity class to video after state is updated
      const video = document.querySelector('video');
      if (video) {
        video.classList.remove('opacity-0');
        video.classList.add('opacity-100');
      }
    }, 100);
  };

  const asteroidPath = getImagePath('non_part/Header/asteroid', isMobile);
  const asteroidSmPath = getImagePath('non_part/Header/asteroid_sm', isMobile);

  return (
    <div ref={ref} className="relative">
      <ParticleSystem 
        ref={particleSystemRef} 
        is_mobile={is_mobile}
        enableMouseParticles={false}
        enableClickParticles={false}
      />      
      <div 
        className="relative w-full h-screen"
        onMouseMove={handleMouseMove}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        onTouchEnd={handleTouchEnd}
        onTouchCancel={handleTouchEnd}
        onClick={handleClick}
      >
        <BackgroundVideo onLoad={handleBackgroundLoad} />

        {/* Asteroid overlay */}
        <div 
          className={`absolute overflow-x-clip inset-0 w-full h-full flex items-center justify-center z-[10] 
            portrait:-translate-y-[10vh] portrait:-translate-x-[25vw] portrait:scale-[1.5]`}
          style={{ 
            opacity: isBackgroundLoaded ? 1 : 0,
            transition: 'opacity 1.0s',
            transitionDelay: '0.0s'
          }}
        >
          <img 
            src={asteroidPath} 
            alt="Asteroid overlay"
            className="w-full object-cover animate-float-x-slow"
          />
        </div>

        {/* Asteroid overlay with delay */}
        <div 
          className={`absolute overflow-x-clip inset-0 w-full h-full flex items-center justify-center z-[10] 
            portrait:-translate-y-[10vh] portrait:-translate-x-[25vw] portrait:scale-[1.5]`}
          style={{ 
            opacity: isBackgroundLoaded ? 1 : 0,
            transition: 'opacity 1.0s',
            transitionDelay: '0.0s'
          }}
        >
          <img 
            src={asteroidSmPath} 
            alt="Asteroid overlay"
            className="w-full object-cover animate-float-x-slow-reverse [animation-delay:1s]"
          />
        </div>


        {/* Rugman Face Logo */}
        <img 
          src="/assets/non_part/Rugman_Face_logo.webp" 
          alt="Rugman Face Logo"
          className="absolute top-8 left-8 z-20 max-md:max-h-[8vh] max-lg:max-h-[17vh] lg:max-h-[7.5vh]"
          style={{ 
            opacity: isBackgroundLoaded ? 1 : 0,
            transition: 'opacity 1.0s',
            transitionDelay: '0s'
          }}
        />

        <div className="relative z-10 h-full h-full">
          <Logo 
            isImageLoaded={isBackgroundLoaded}
            particleSystemRef={particleSystemRef}
          />

          {/* Decorative Elements */}
          <div
            className="absolute bottom-0 left-0 right-0"
            style={{ 
              opacity: isBackgroundLoaded ? 1 : 0,
              transition: 'opacity 0.8s',
              transitionDelay: '1.2s'
            }}
          >
            <div className="h-24 bg-gradient-to-t from-black/20 to-transparent" />
          </div>

          <ScrollIndicator 
            isImageLoaded={isBackgroundLoaded}
            scrollToAfterScrollingSequence={scrollToAfterScrollingSequence}
          />
        </div>
      </div>
    </div>
  );
});

export default LandingHeader;
