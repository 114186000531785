import React from 'react';

const Friends = () => {
  const friends = [
    {
      name: "Hypurr Collective",
      description: "A collective of founders, builders, traders, and power-users working together to contribute towards Hyperliquid’s ecosystem growth and success. Join us in working together to shape the future of Hyperliquid.",
      image: "/assets/non_part/Meet/Friends/1-Hypurrcollective.webp"
    },
    {
      name: "Catbal",
      description: "Awaken to the Pawawan’s Journey as Eke, a young, bright, and ambitious cat  discovers the ways of a secret cat cabal on Hyperliquid. Alongside RUGMAN, Catbal is positioned as a top contender for HL’s next creative-first unicorn. Catbalio.",
      image: "/assets/non_part/Meet/Friends/2-Catbal.webp"
    },
    {
      name: "PvP.Trade",
      description: "PvP.trade is a telegram bot you can add to your trading groups. Once added, you and your group members can track each others' positions and place trades in real-time. Share alpha, copy, or countertrade your friends, all in the comfort of your existing telegram group.",
      image: "/assets/non_part/Meet/Friends/3-PvPTrade.webp"
    }
  ];

  return (
    <section className="px-6 py-12 max-w-7xl mx-auto">
      <div className="flex items-center justify-center gap-4 mb-8">
        <img 
          src="/assets/non_part/Rugman_Face_logo.webp"
          alt="Friends Logo" 
          className="w-12 h-12 object-contain"
        />
        <h2 className="meet-rugman-text text-white text-3xl md:text-4xl">Our Hyper Friends</h2>
      </div>

      <div className="flex flex-col gap-8">
        {friends.map((friend, index) => (
          <div
            key={index} 
            className="flex flex-col sm:flex-row items-center sm:items-start gap-4 bg-gray-800 p-6 rounded-lg shadow-sm"
          >
            <img 
              src={friend.image} 
              alt={friend.name} 
              className="w-16 h-16 object-contain lg:w-32 lg:h-32"
            />
            <div className="flex-1 text-center sm:text-left">
              <p className="meet-rugman-text text-white">
                <strong>{friend.name}:</strong>
              </p>
              <p className="meet-rugman-text mt-2 text-white">
                {friend.description}
              </p>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Friends;
