import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';

const NavBar = ({ links }) => {
  const [isOpen, setIsOpen] = useState(false);

  const allLinks = [
    ...links
  ];

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const menuVariants = {
    closed: {
      x: "100%",
      borderTopLeftRadius: "50vw",
      borderBottomLeftRadius: "50vw",
      opacity: 0,
      transition: {
        type: "spring",
        stiffness: 400,
        damping: 40
      }
    },
    open: {
      x: 0,
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
      opacity: 1,
      transition: {
        type: "spring",
        stiffness: 400,
        damping: 40
      }
    }
  };

  const linkVariants = {
    closed: {
      x: 25,
      opacity: 0
    },
    open: (i) => ({
      x: 0,
      opacity: 1,
      transition: {
        delay: i * 0.1,
        type: "spring",
        stiffness: 100,
        damping: 2
      }
    })
  };

  const floatingLinkVariants = {
    initial: {
      x: 0,
      y: 0,
      rotate: 0,
      transformOrigin: "left center"
    },
    hover: {
      x: 20,
      y: -10,
      rotate: 2,
      transition: {
        type: "spring",
        stiffness: 300,
        damping: 5
      }
    },
    animate: {
      y: [-2, 2, -2],
      transition: {
        duration: 3,
        repeat: Infinity,
        ease: "easeInOut"
      }
    }
  };

  return (
    <>
      <div
        className="fixed top-8 right-8 items-center text-white"
        onClick={toggleMenu}
      >
        <motion.button
          whileTap={{ scale: 0.5 }}
          whileHover={{ scale: 1.05, rotate: 180 }}
          className="text-3xl bg-white text-black hover:text-indigo-500 transition-colors p-4 rounded-full"
        >
          <svg
            stroke="currentColor"
            fill="none"
            strokeWidth="2"
            viewBox="0 0 24 24"
            strokeLinecap="round"
            strokeLinejoin="round"
            height="1em"
            width="1em"
          >
            <line x1="3" y1="12" x2="21" y2="12"></line>
            <line x1="3" y1="6" x2="21" y2="6"></line>
            <line x1="3" y1="18" x2="21" y2="18"></line>
          </svg>
        </motion.button>
      </div>

      <AnimatePresence>
        {isOpen && (
          <motion.nav
            initial="closed"
            animate="open"
            exit="closed"
            variants={menuVariants}
            className="fixed top-0 bottom-0 w-screen bg-white"
          >
            <motion.button
              whileTap={{ scale: 0.95 }}
              whileHover={{ scale: 1.05 }}
              onClick={toggleMenu}
              className="text-3xl bg-white text-black hover:text-indigo-500 border-[1px] border-transparent hover:border-indigo-500 transition-colors p-6 rounded-full absolute top-8 right-8"
            >
              <svg
                stroke="currentColor"
                fill="none"
                strokeWidth="2"
                viewBox="0 0 24 24"
                strokeLinecap="round"
                strokeLinejoin="round"
                height="1em"
                width="1em"
              >
                <line x1="18" y1="6" x2="6" y2="18"></line>
                <line x1="6" y1="6" x2="18" y2="18"></line>
              </svg>
            </motion.button>

            <div className="flex flex-col gap-4 absolute bottom-8 left-8">
              {allLinks.map((link, i) => (
                <motion.div
                  key={link.name}
                  custom={i}
                  variants={linkVariants}
                >
                  <motion.button
                    onClick={() => {
                      link.onClick();
                      toggleMenu();
                    }}
                    className="clickable inline-block z-10 text-slate-800 w-fit font-black text-6xl hover:text-indigo-500 transition-colors mobile-float"
                    variants={floatingLinkVariants}
                    initial="initial"
                    whileHover="hover"
                    animate={{
                      y: [-2, 2, -2],
                      transition: {
                        duration: 2,
                        repeat: Infinity,
                        ease: "easeInOut"
                      }
                    }}
                    style={{
                      "@media (hover: hover)": {
                        animation: "none"
                      }
                    }}
                  >
                    {link.name}
                  </motion.button>
                </motion.div>
              ))}
            </div>
          </motion.nav>
        )}
      </AnimatePresence>
    </>
  );
};

export default NavBar;
