import React from 'react';
import { Maximize, RefreshCcw, Download } from 'lucide-react';

function ImageControlsPanel({ downloadWidth, setDownloadWidth, resetTransforms, downloadCharacter, hasSelectedParts, shouldCropToSquare, toggleCropToSquare }) {
  return (
    <div className="mt-4 p-4 bg-white rounded-lg shadow">
      {/* Download width Control */}
      <div className="space-y-2">
        <label className="flex items-center gap-2">
          <Maximize size={16} /> Image size
        </label>
        <input
          type="range"
          min="500"
          max="2000"
          step="250"
          value={downloadWidth}
          onChange={(e) => setDownloadWidth(Number(e.target.value))}
          className="w-full"
        />
        <span className="text-sm font-medium min-w-[4rem]">
          {downloadWidth.toFixed(0)}px
        </span>
        
        {/* Reset Positions Button */}
        <button
          onClick={resetTransforms}
          className="mt-6 flex items-center gap-2 px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition-colors disabled:bg-gray-400 disabled:cursor-not-allowed"
          disabled={!hasSelectedParts}
        >
          <RefreshCcw size={20} />
          Reset all transforms
        </button>

        <label style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
            <input
                type="checkbox"
                checked={shouldCropToSquare}
                onChange={toggleCropToSquare}
                className="custom-checkbox" // Add a class for custom styling
            />
            <span style={{ marginLeft: '8px' }}>
                {shouldCropToSquare ? 'Disable Square Cropping' : 'Enable Square Cropping'}
            </span>
        </label>

        {/* Download Button */}
        <button
          onClick={downloadCharacter}
          className="mt-6 flex items-center gap-2 px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition-colors disabled:bg-gray-400 disabled:cursor-not-allowed"
          disabled={!hasSelectedParts}
        >
          <Download size={20} />
          Download Character
        </button>
      </div>
    </div>
  );
}

export default ImageControlsPanel;
