import React from 'react';
import { motion } from 'framer-motion';

const ScrollIndicator = ({ isImageLoaded, scrollToAfterScrollingSequence }) => {
  return (
    <div className="absolute bottom-0 left-0 right-0 flex justify-center max-lg:landscape:justify-end items-center pb-8 max-lg:landscape:pr-8">
      <motion.div
        className="flex flex-col items-center text-white cursor-pointer"
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: isImageLoaded ? 1 : 0, y: isImageLoaded ? 0 : -20 }}
        transition={{ delay: 2, duration: 0.5 }}
        onClick={scrollToAfterScrollingSequence}
        style={{ zIndex: 100 }}
      >
        <motion.div
        className='clickable'
          animate={{
            y: [0, 8, 0],
          }}
          transition={{
            duration: 1.5,
            repeat: Infinity,
            repeatType: "reverse"
          }}
        >
          <svg
            className="w-12 h-12 clickable "
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path d="M19 14l-7 7m0 0l-7-7m7 7V3"></path>
          </svg>
        </motion.div>
        <span className="clickable mt-2 text-lg font-light tracking-wider">Tap here</span>
      </motion.div>
    </div>
  );
};

export default ScrollIndicator;
