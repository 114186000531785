import { useState, useEffect } from 'react';

// Hook to detect mobile devices
export const useIsMobile = () => {
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        const checkMobile = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        // Initial check
        checkMobile();

        // Add event listener for window resize
        window.addEventListener('resize', checkMobile);

        // Cleanup
        return () => window.removeEventListener('resize', checkMobile);
    }, []);

    return isMobile;
};

// Non-hook version of ImagePath
export const getImagePath = (basePath, isMobile, isVideo = false) => {
    // Remove any leading slash and 'assets' from the path if present
    const cleanPath = basePath.replace(/^\/?(assets\/)?/, '');

    const extension = isVideo ? '.webm' : '.webp';

    // Construct the full path
    const fullPath = `/assets/${cleanPath}${isMobile ? '_mobile' : ''}${extension}`;
    return fullPath;
};

// React component version if needed
export const ImageComponent = ({ path, ...props }) => {
    const isMobile = useIsMobile();
    const fullPath = getImagePath(path, isMobile);

    return (
        <img src={fullPath} {...props} alt={props.alt || ''} />
    );
};
