import React, { useRef, useEffect, useState } from 'react';
import { useIsMobile, getImagePath } from '../../utils/imageUtils';

const BackgroundVideo = ({ onLoad }) => {
  const isDeviceMobile = useIsMobile();
  const videoRef = useRef();
  const [opacity, setOpacity] = useState(0); // Start with 0 opacity
  const [initialLoad, setInitialLoad] = useState(true);
  const animationFrameRef = useRef();

  const starsVideoPath = getImagePath('non_part/Header/Header_Stars', isDeviceMobile, true);

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.addEventListener('loadeddata', () => {
        // Start fade in animation
        setInitialLoad(true);
        setTimeout(() => {
          setOpacity(1);
          // Call onLoad after fade-in completes
          setTimeout(() => {
            onLoad();
          }, 1000);
        }, 0);
      });
    }

    const handleScroll = () => {
      if (animationFrameRef.current) {
        cancelAnimationFrame(animationFrameRef.current);
      }

      animationFrameRef.current = requestAnimationFrame(() => {
        const scrollPosition = window.scrollY;
        const windowHeight = window.innerHeight;
        
        const fadeStart = windowHeight * 0.15;
        const fadeEnd = windowHeight * 1.2;
        
        if (scrollPosition <= fadeStart) {
          setOpacity(1);
        } else if (scrollPosition >= fadeEnd) {
          setOpacity(0);
        } else {
          const fadeRange = fadeEnd - fadeStart;
          const fadeAmount = (scrollPosition - fadeStart) / fadeRange;
          setOpacity(Math.max(0, Math.min(1, 1 - fadeAmount)));
        }
        setInitialLoad(false);
      });
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
      if (animationFrameRef.current) {
        cancelAnimationFrame(animationFrameRef.current);
      }
    };
  }, [onLoad]);

  return (
    <div className="absolute top-0 left-0 w-full h-[160vh] portrait:h-[180vh] overflow-x-hidden z-10">
      <video
        ref={videoRef}
        autoPlay
        loop
        muted
        playsInline
        className="absolute w-auto min-w-full min-h-full max-w-none object-cover"
        style={{
          left: '50%',
          top: '50%',
          transform: 'translate(-50%, -50%)',
          opacity: opacity,
          transition: initialLoad ? 'opacity 1000ms ease-in' : 'opacity 100ms ease-out'
        }}
      >
        <source src={starsVideoPath} type="video/webm" />
      </video>
    </div>
  );
};

export default BackgroundVideo;
