export const scrollToSection = (ref, rugatarRef) => {
    const element = ref.current;
    if (!element) return;

    const elementPosition = element.getBoundingClientRect().top + window.scrollY;
    const startPosition = window.scrollY;
    var duration;
    if (ref === rugatarRef && startPosition < 1000) {
        duration = 8000;
    } else {
        duration = 1500;
    }
    const startTime = performance.now();

    function animate(currentTime) {
        const elapsed = currentTime - startTime;
        const progress = Math.min(elapsed / duration, 1);

        let easedProgress;
        if (ref === rugatarRef && startPosition < 1000) {
            easedProgress = progress;
        } else {
            easedProgress = progress < 0.5
                ? 4 * progress * progress * progress
                : 1 - Math.pow(-2 * progress + 2, 3) / 2;
        }

        window.scrollTo({
            top: startPosition + (elementPosition - startPosition) * easedProgress,
        });

        if (progress < 1) {
            requestAnimationFrame(animate);
        }
    }

    requestAnimationFrame(animate);
};
