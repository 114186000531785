import React, { useEffect, useRef, useState } from 'react';
import { motion, useScroll, useTransform, useInView } from 'framer-motion';
import { useIsMobile, getImagePath } from '../utils/imageUtils';

const ScrollSequence = ({
  scrollHeight = 8000,
  numFrames = 120,
  desktopDimensions = { width: 3840, height: 2160 },
  mobileDimensions = { width: 1080, height: 1920 },
  imagePrefix = './assets/landing/',
  mobilePrefixSuffix = '_mobile',
  imageExtension = '.webp',
}) => {
  const containerRef = useRef(null);
  const canvasRef = useRef(null);
  const viewRef = useRef(null);
  const [images, setImages] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
  const isDeviceMobile = useIsMobile();
  const isInView = useInView(viewRef, { margin: "-25% 0px -25% 0px" });


  const { scrollYProgress } = useScroll({
    target: containerRef,
    offset: ['start start', 'end end'],
  });

  const frameIndex = useTransform(
    scrollYProgress,
    [0, 1],
    [0, numFrames - 1]
  );

  const opacity = useTransform(
    scrollYProgress,
    [0, 0.1, 0.9, 1], // Adjust these values to control when the fade starts/ends
    [0, 1, 1, 0]
  );

  useEffect(() => {
    const updateDimensions = () => {
      // Wait for the actual orientation change to complete
      setTimeout(() => {
        const refDimensions = isDeviceMobile ? mobileDimensions : desktopDimensions;
        const containerWidth = document.documentElement.clientWidth;
        const aspectRatio = refDimensions.height / refDimensions.width;
        const width = Math.min(containerWidth, refDimensions.width);
        const height = width * aspectRatio;
        
        setDimensions({ width, height });
      }, 100); // Small delay to ensure orientation change is complete
    };
  
    updateDimensions();
    
    window.addEventListener('resize', updateDimensions);
    window.addEventListener('orientationchange', updateDimensions);
    
    return () => {
      window.removeEventListener('resize', updateDimensions);
      window.removeEventListener('orientationchange', updateDimensions);
    };
  }, [isDeviceMobile]);

  useEffect(() => {
    const loadImages = async () => {
      const imagePromises = Array.from({ length: numFrames }, (_, i) => {
        return new Promise((resolve) => {
          const img = new Image();
          const frameNumber = (i + 1).toString().padStart(4, '0');
          const path = isDeviceMobile
            ? `${imagePrefix.replace(/\/$/, mobilePrefixSuffix + '/')}${frameNumber}${imageExtension}`
            : `${imagePrefix}${frameNumber}${imageExtension}`;
          
          img.onload = () => resolve(img);
          img.src = path;
        });
      });

      const loadedImages = await Promise.all(imagePromises);
      setImages(loadedImages);
      setIsLoading(false);
    };

    loadImages();
  }, [isDeviceMobile]);

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas?.getContext('2d');
    if (!canvas || !ctx || images.length === 0) return;

    const drawFrame = () => {
      const index = Math.round(frameIndex.get());
      if (images[index]) {
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        ctx.drawImage(images[index], 0, 0, canvas.width, canvas.height);
      }
    };

    canvas.width = dimensions.width;
    canvas.height = dimensions.height;
    
    const unsubscribe = frameIndex.on('change', drawFrame);
    return () => unsubscribe();
  }, [images, dimensions, frameIndex]);

  // Get cloud image paths
  const cloudLayer0Path = getImagePath('non_part/ScrollSeqClouds/ScrollSeqClouds_0', isDeviceMobile);
  const cloudLayer1Path = getImagePath('non_part/ScrollSeqClouds/ScrollSeqClouds_1', isDeviceMobile);
  const cloudLayer2Path = getImagePath('non_part/ScrollSeqClouds/ScrollSeqClouds_2', isDeviceMobile);

  return (
    <div ref={containerRef} style={{ height: scrollHeight }} className="relative w-full">
      <div ref={viewRef} className="sticky top-0 w-full h-screen flex items-center justify-center">
        {isLoading ? (
          <div className="text-2xl">Loading...</div>
        ) : (
          <div className="relative w-full">
            <div className="absolute inset-0 flex items-center justify-center overflow-x-clip">
              <motion.img src={cloudLayer0Path} className="absolute w-full h-auto object-contain z-10 animate-wave-slow" />
              <motion.img src={cloudLayer1Path} className="absolute w-full h-auto object-contain z-20 animate-wave-slow" />
              <motion.img src={cloudLayer2Path} className="absolute w-full h-auto object-contain z-30 animate-float-x-slow" />
            </div>
            
            <motion.div 
              className="relative z-40 mx-auto" 
              style={{ 
                width: dimensions.width, 
                height: dimensions.height,
                opacity 
              }}
              initial={{ opacity: 0 }}
              animate={{ opacity: isInView ? 1 : 0 }}
              transition={{ duration: 0.5 }}
            >
              <canvas
                ref={canvasRef}
                style={{
                  width: '100%',
                  height: '100%',
                }}
              />
            </motion.div>
          </div>
        )}
      </div>
    </div>
  );
};


export default ScrollSequence;
