import React, { useEffect, useRef, useState } from 'react';
import SVGHyperliquid from './Header/SVGHyperliquid';
import { useIsMobile, getImagePath } from '../utils/imageUtils';
import '../App.css';

function MeetRugman() {
  const isDeviceMobile = useIsMobile();
  const [isVisible, setIsVisible] = useState(false);
  const componentRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsVisible(entry.isIntersecting);
      },
      {
        threshold: 0.25
      }
    );

    if (componentRef.current) {
      observer.observe(componentRef.current);
    }

    return () => {
      if (componentRef.current) {
        observer.unobserve(componentRef.current);
      }
    };
  }, []);

  const fadeUpStyle = {
    opacity: isVisible ? 1 : 0,
    transform: `translate(-50%, ${isVisible ? '0' : isDeviceMobile ? '100px' : '300px'})`,
    transition: 'opacity 1.5s ease-out, transform 1.5s ease-out',
  };

  const fadeLeftStyle = {
    opacity: isVisible ? 1 : 0,
    transform: `translateX(${isVisible ? '0' : '-100px'})`,
    transition: 'opacity 1.5s ease-out 0.5s, transform 1.5s ease-out 0.5s',
  };

  const fadeRightStyle = {
    opacity: isVisible ? 1 : 0,
    transform: `translateX(${isVisible ? '0' : '100px'})`,
    transition: 'opacity 1.5s ease-out 1s, transform 1.5s ease-out 1s',
  };

  const fadeRightLogoStyle = {
    opacity: isVisible ? 1 : 0,
    transform: `translateX(${isVisible ? '0' : '100px'})`,
    transition: 'opacity 1.5s ease-out 1.5s, transform 1.5s ease-out 1.5s',
  };

  const rugmanLogoPath = getImagePath('/assets/non_part/Meet/RugmanLogo', isDeviceMobile);
  const mindmapPath = getImagePath('/assets/non_part/Meet/Mindmap', isDeviceMobile);
  const rugmanSittingPath = getImagePath('/assets/non_part/Meet/RugmanSitting', isDeviceMobile);

  return (
    <div className='w-full relative overflow-hidden' style={{pointerEvents: 'none'}} ref={componentRef}>
      {/* Title and Text Overlay */}
      <div 
        style={fadeUpStyle}
        className={`relative left-1/2 top-[0vw] z-10 text-center py-10`}
      >
        <h2 className="text-white text-6xl font-bold mb-4 meet-rugman-text">
          <strong>Meet <img src={rugmanLogoPath} alt="RUGMAN" style={{ height: '100px', display: 'inline', verticalAlign: 'middle' }} /></strong>
        </h2>
        <p className="text-white max-w-[30vw] portrait:max-w-[85vw] max-lg:max-w-[70vw] mx-auto text-xl meet-rugman-text">
          <strong>Rugman</strong> is a wholesome <strong>hero</strong> living on Hyperliquid. His life's dream is to become a successful quant, but all his friends think he's a left curver! Join RUGMAN on his adventures as he discovers how quantitative he truly is.
        </p>

        {/* Main Images */}
        <div className="flex flex-col items-center max-w-[80vw] mx-auto max-md:mt-8">
          <div className="flex flex-row justify-center items-center gap-4 max-md:gap-0 max-md:flex-col">
            <div style={fadeLeftStyle}>
              <img 
                src={mindmapPath}
                alt="Mindmap" 
                className="w-[34vw] max-md:w-[80vw] object-contain"
              />
            </div>
            <div style={fadeRightStyle}>
              <img 
                src={rugmanSittingPath} 
                alt="Rugman Sitting" 
                className="w-[28vw] max-md:w-[80vw] object-contain"
              />
            </div>
          </div>
        </div>
      </div>

      {/* Hyperliquid Logo - Positioned absolutely */}
      <div 
        className="absolute bottom-16 right-4"
        style={fadeRightLogoStyle}
      >
        <SVGHyperliquid className="w-[20vw] max-md:w-[40vw] object-contain" />
        {/* <img 
          src={hyperliquidLogoPath}
          alt="Hyperliquid" 
          className="w-[20vw] max-md:w-[40vw] object-contain"
        /> */}
      </div>
    </div>
  );
}

export default MeetRugman;
