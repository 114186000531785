import React, { useRef, useEffect, useState } from 'react';

const ParallaxObject = ({
  imageSrc,
  positionX = 50,
  positionY = 50,
  parallaxSpeed = 1,
  width,
  height,
  zIndex = 0,
  className = '',
  style = {},
  scrollThreshold = 0, // New prop to control when the object becomes visible
}) => {
  const [offset, setOffset] = useState(0);
  const [opacity, setOpacity] = useState(0);
  const objectRef = useRef(null);
  const containerRef = useRef(null);
  const [isInView, setIsInView] = useState(false);
  const [imageLoaded, setImageLoaded] = useState(false);
  const [naturalDimensions, setNaturalDimensions] = useState({ width: null, height: null });
  const [viewportHeight, setViewportHeight] = useState(window.innerHeight);

  useEffect(() => {
    // Preload image to get natural dimensions
    const img = new Image();
    img.onload = () => {
      setImageLoaded(true);
      setNaturalDimensions({
        width: img.naturalWidth,
        height: img.naturalHeight
      });
    };
    img.src = imageSrc;

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          setIsInView(entry.isIntersecting);
        });
      },
      { threshold: [0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1] }
    );

    if (containerRef.current) {
      observer.observe(containerRef.current);
    }

    // Handle viewport resize
    const handleResize = () => {
      setViewportHeight(window.innerHeight);
    };
    window.addEventListener('resize', handleResize);

    return () => {
      if (containerRef.current) {
        observer.unobserve(containerRef.current);
      }
      window.removeEventListener('resize', handleResize);
    };
  }, [imageSrc]);

  useEffect(() => {
    const handleScroll = () => {
      if (!isInView || !containerRef.current) return;

      const scrollY = window.scrollY;
      const containerRect = containerRef.current.getBoundingClientRect();
      const scrollProgress = (window.innerHeight - containerRect.top) / window.innerHeight;
      
      // Calculate parallax offset based on scroll position
      const parallaxOffset = scrollProgress * parallaxSpeed * 100;
      setOffset(parallaxOffset);

      // Calculate opacity based on scroll threshold
      if (scrollThreshold > 0) {
        const opacityProgress = Math.min(1, scrollY / (scrollThreshold * viewportHeight));
        setOpacity(opacityProgress);
      } else {
        setOpacity(1);
      }
    };

    window.addEventListener('scroll', handleScroll, { passive: true });
    handleScroll(); // Initial calculation
    return () => window.removeEventListener('scroll', handleScroll);
  }, [parallaxSpeed, isInView, scrollThreshold, viewportHeight]);

  // Helper function to process dimension values
  const getDimensionValue = (value, naturalValue) => {
    if (!value) return `${naturalValue}px`;
    if (typeof value === 'number') return `${value}px`;
    return value;
  };

  // Calculate responsive position
  const getResponsivePosition = () => {
    const isMobile = window.innerWidth <= 768;
    const baseY = typeof positionY === 'number' ? positionY : parseFloat(positionY);
    
    // Adjust Y position based on viewport size
    const adjustedY = isMobile ? Math.max(baseY, baseY) : baseY;
    
    return {
      x: `${positionX}%`,
      y: `${adjustedY}%`
    };
  };

  const position = getResponsivePosition();

  const containerStyle = {
    position: 'absolute',
    left: position.x,
    top: position.y,
    transform: `translate(-50%, -50%) translateY(${offset}px)`,
    zIndex,
    width: getDimensionValue(width, naturalDimensions.width),
    height: getDimensionValue(height, naturalDimensions.height),
    transition: 'opacity 0.3s ease-in-out',
    display: 'block',
    minHeight: style.minHeight || '300px',
    ...style,
  };

  const imageStyle = {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundImage: `url(${imageSrc})`,
    backgroundSize: 'contain',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    minHeight: 'inherit',
  };

  return (
    <div
      ref={containerRef}
      className={`absolute ${className}`}
      style={containerStyle}
    >
      <div
        ref={objectRef}
        style={imageStyle}
      />
    </div>
  );
};

export default ParallaxObject;
