import React from "react";
import { motion } from 'framer-motion';

const SVGHyperliquid = ({
  ...props 
}) => {
  return (
    <>
    <motion.svg
      width={"100%"}
      height={"100%"}
      viewBox="100 100 220 50"
      overflow={"visible"}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      {/* Hyperliquid Logo */}
      <motion.g
      >
        <path d="M127.768 117.041C127.768 128.532 120.74 132.22 117.013 128.96C113.978 126.287 113.073 120.622 108.494 120.034C102.69 119.339 102.158 127.089 98.3243 127.089C93.8519 127.089 93 120.622 93 117.255C93 113.834 93.9584 109.184 97.7386 109.184C102.158 109.184 102.424 115.865 107.961 115.491C113.445 115.117 113.552 108.168 117.172 105.229C120.314 102.61 127.768 105.389 127.768 117.041Z"
          fill="white"
        />
      </motion.g>

      {/* Hyperliquid title */}
      <motion.g
      >
        <g>
          <path
            d="M144.273 127.73V105.442H146.403V115.117H159.395V105.442H161.471V127.784H159.395V116.934H146.403V127.784H144.273V127.73Z"
            fill="white"
          />
          <path
            d="M167.008 134.519L169.883 127.357L163.653 112.338H165.836L169.936 122.493C170.149 123.134 170.522 123.936 170.948 124.951C171.001 124.791 171.054 124.631 171.161 124.47C171.214 124.31 171.267 124.15 171.374 123.989C171.48 123.722 171.64 123.455 171.693 123.188C171.746 122.92 171.853 122.707 171.959 122.439L175.793 112.284H177.869L169.137 134.465H167.008V134.519Z"
            fill="white"
          />
          <path
            d="M179.893 134.519V112.338H181.863V113.3C181.863 113.514 181.863 113.674 181.863 113.888C181.863 114.102 181.863 114.315 181.863 114.529C181.863 114.636 181.863 114.743 181.863 114.85C181.863 114.957 181.863 115.064 181.863 115.171C182.395 114.208 183.087 113.407 184.099 112.819C185.057 112.231 186.122 111.91 187.347 111.91C188.784 111.91 190.009 112.284 191.074 112.979C192.139 113.674 192.937 114.636 193.523 115.865C194.109 117.095 194.375 118.484 194.375 120.034C194.375 121.638 194.109 123.027 193.523 124.257C192.937 125.486 192.085 126.448 191.074 127.196C190.009 127.891 188.784 128.265 187.453 128.265C186.282 128.265 185.27 127.998 184.259 127.41C183.3 126.876 182.555 126.021 181.969 124.952C181.969 125.112 181.969 125.272 181.969 125.486C181.969 125.646 181.969 125.807 181.969 125.967C181.969 126.074 181.969 126.234 181.969 126.341C181.969 126.448 181.969 126.555 181.969 126.662V134.572H179.893V134.519ZM187.027 126.608C188.092 126.608 188.997 126.341 189.796 125.753C190.594 125.165 191.18 124.417 191.659 123.402C192.085 122.386 192.298 121.264 192.298 119.981C192.298 118.698 192.085 117.576 191.659 116.614C191.233 115.652 190.594 114.85 189.796 114.315C188.997 113.781 188.092 113.514 187.027 113.514C186.016 113.514 185.11 113.781 184.312 114.315C183.513 114.85 182.874 115.598 182.448 116.614C182.022 117.576 181.756 118.752 181.756 120.034C181.756 121.317 181.969 122.493 182.448 123.455C182.874 124.471 183.513 125.219 184.312 125.807C185.11 126.395 186.016 126.608 187.027 126.608Z"
            fill="white"
          />
          <path
            d="M203.799 128.212C202.362 128.212 201.084 127.891 199.966 127.25C198.848 126.608 197.996 125.646 197.41 124.417C196.824 123.187 196.505 121.691 196.505 119.981C196.505 118.431 196.824 117.041 197.463 115.812C198.102 114.582 198.954 113.62 200.019 112.925C201.084 112.231 202.308 111.856 203.639 111.856C205.077 111.856 206.302 112.177 207.367 112.872C208.378 113.513 209.177 114.475 209.709 115.651C210.242 116.881 210.508 118.27 210.508 119.874V120.301H198.475C198.475 121.637 198.688 122.76 199.167 123.722C199.646 124.684 200.232 125.379 201.031 125.86C201.829 126.341 202.788 126.608 203.799 126.608C205.024 126.608 206.035 126.341 206.887 125.753C207.686 125.165 208.218 124.256 208.378 123.081H210.348C210.188 124.043 209.869 124.951 209.283 125.7C208.751 126.448 208.005 127.089 207.047 127.517C206.195 127.944 205.077 128.212 203.799 128.212ZM208.378 118.805C208.378 117.201 207.952 115.919 207.1 114.956C206.248 113.994 205.13 113.513 203.639 113.513C202.202 113.513 201.031 113.994 200.125 115.01C199.22 116.025 198.688 117.255 198.528 118.805H208.378Z"
            fill="white"
          />
          <path
            d="M213.543 127.731V112.338H215.513V113.353C215.513 113.514 215.513 113.674 215.513 113.834C215.513 113.995 215.513 114.155 215.513 114.315C215.513 114.476 215.513 114.689 215.513 114.85C215.513 115.01 215.513 115.224 215.513 115.438C215.939 114.422 216.578 113.567 217.536 112.926C218.441 112.284 219.506 111.91 220.731 111.91C221.05 111.91 221.316 111.91 221.583 111.964V113.888C221.21 113.834 220.891 113.834 220.624 113.834C219.613 113.834 218.708 114.048 217.962 114.529C217.217 115.01 216.631 115.652 216.205 116.507C215.779 117.362 215.566 118.377 215.566 119.5V127.784H213.543V127.731Z"
            fill="white"
          />
          <path
            d="M226.48 127.303L226.374 127.731H220.145L220.251 127.303C221.955 127.143 222.7 126.609 222.966 125.326L226.48 108.703C226.853 106.993 226.321 106.726 224.51 107.1L224.617 106.672L228.45 105.39H228.876L224.67 125.379C224.351 126.609 224.83 127.143 226.48 127.303Z"
            fill="white"
          />
          <path
            d="M233.562 127.303L233.456 127.73H227.173L227.279 127.303C228.983 127.142 229.729 126.608 229.995 125.325L232.124 115.17C232.497 113.46 232.018 113.192 230.154 113.567L230.261 113.139L234.094 111.856H234.52L231.699 125.325C231.432 126.608 231.912 127.142 233.562 127.303ZM234.627 106.244C235.479 106.244 236.011 106.886 235.905 107.741C235.798 108.596 235.053 109.184 234.254 109.184C233.402 109.184 232.817 108.542 232.923 107.741C232.976 106.886 233.722 106.244 234.627 106.244Z"
            fill="white"
          />
          <path
            d="M244.317 132.22L246.021 124.256C244.956 126.608 242.667 128.212 240.324 128.212C236.491 128.212 234.68 124.577 235.852 119.179C236.65 115.224 240.164 111.856 244.317 111.856C246.234 111.856 247.832 112.712 248.47 113.994L249.908 111.856H250.334L245.968 132.22C245.649 133.61 246.767 133.877 247.885 134.091L247.778 134.519H240.59L240.697 134.091C242.827 133.877 244.051 133.61 244.317 132.22ZM241.389 127.089C243.359 127.089 245.329 125.379 246.287 123.348L247.565 117.255C247.885 115.063 246.873 112.765 243.838 112.765C240.963 112.765 238.514 115.384 237.715 119.179C236.757 123.829 238.141 127.089 241.389 127.089Z"
            fill="white"
          />
          <path
            d="M271.791 127.303L271.684 127.73H265.401L265.508 127.303C267.212 127.142 267.957 126.608 268.223 125.325L270.353 115.17C270.726 113.46 270.247 113.192 268.383 113.567L268.489 113.139L272.323 111.856H272.749L269.927 125.325C269.661 126.608 270.14 127.142 271.791 127.303ZM272.855 106.244C273.707 106.244 274.24 106.886 274.133 107.741C274.027 108.596 273.281 109.184 272.483 109.184C271.631 109.184 271.045 108.542 271.152 107.741C271.258 106.886 272.004 106.244 272.855 106.244Z"
            fill="white"
          />
          <path
            d="M262.899 125.272L264.336 118.484L265.721 111.856H265.295L261.461 113.139L261.355 113.567C263.165 113.193 263.644 113.46 263.325 115.17L261.515 123.829C259.491 125.7 258.107 126.822 256.35 126.822C254.22 126.822 253.102 125.379 253.635 122.867L255.977 111.856H255.551L251.718 113.139L251.611 113.567C253.422 113.193 253.954 113.46 253.581 115.17L251.931 123.081C251.292 126.02 252.783 128.158 255.498 128.158C257.308 128.158 258.906 127.143 261.408 124.47L261.302 125.005L260.769 127.624H264.762L264.869 127.143C263.112 127.089 262.633 126.608 262.899 125.272Z"
            fill="white"
          />
          <path
            d="M288.51 111.269L289.734 105.336H289.308L285.475 106.619L285.368 107.046C287.178 106.672 287.658 106.939 287.338 108.65L286.273 113.727C285.581 112.605 284.09 111.857 282.387 111.857C278.18 111.857 274.72 115.224 273.814 119.446C272.75 124.577 274.507 128.212 278.34 128.212C280.736 128.212 283.025 126.555 284.09 124.203L283.824 125.539L283.345 127.731L287.338 127.677L287.445 127.25C285.794 127.089 285.315 126.555 285.581 125.272L288.51 111.269ZM284.25 123.295C283.345 125.379 281.322 127.089 279.352 127.089C276.104 127.089 274.72 123.829 275.625 119.446C276.477 115.331 278.979 112.765 281.854 112.765C284.942 112.765 285.954 115.224 285.528 117.415L285.368 118.11L284.25 123.295Z"
            fill="white"
          />
        </g>
      </motion.g>


    </motion.svg>
    </>
    )
  };
export default SVGHyperliquid;
