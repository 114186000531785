import React, { useState, useRef, useEffect } from 'react';
import { RefreshCcw } from 'lucide-react';

// Component for the loading spinner
const LoadingSpinner = () => (
  <div className="col-span-3 flex justify-center items-center py-8">
    <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-white"></div>
  </div>
);

const PartItem = ({ item, index, isSelected, category, onClick, itemName }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [hasError, setHasError] = useState(false);
  const imageRef = useRef(null);
  const containerRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        // When the component enters viewport
        if (entries[0].isIntersecting) {
          setIsVisible(true);
          // Disconnect observer after first intersection
          observer.disconnect();
        }
      },
      {
        root: null,
        rootMargin: '50px', // Start loading slightly before the element is visible
        threshold: 0.1
      }
    );

    if (containerRef.current) {
      observer.observe(containerRef.current);
    }

    return () => {
      observer.disconnect();
    };
  }, []);

  const handleImageLoad = () => {
    setIsLoaded(true);
  };

  const handleImageError = () => {
    setHasError(true);
    setIsLoaded(true);
  };

  return (
    <div
      ref={containerRef}
      className={`bg-white p-2 rounded-lg shadow cursor-pointer hover:bg-gray-50
        ${isSelected ? 'ring-2 ring-blue-500' : ''}`}
      onClick={onClick}
    >
      <div className="aspect-video bg-gray-300 rounded flex items-center justify-center overflow-hidden relative">
        {/* Show loading skeleton while image is loading */}
        {!isLoaded && (
          <div className="absolute inset-0 bg-gray-200 animate-pulse" />
        )}

        {/* Show error state if image failed to load */}
        {hasError && (
          <div className="absolute inset-0 flex items-center justify-center bg-gray-100">
            <span className="text-gray-400 text-sm">Failed to load image</span>
          </div>
        )}

        {/* Only render img tag when element is visible */}
        {isVisible && (
          <img
            ref={imageRef}
            crossOrigin={category === 'meme_image' ? "anonymous" : undefined}
            src={item}
            alt={`${category} ${index + 1}`}
            className={`w-full h-full clickable ${
              category === 'meme_image' ? 'object-cover' : 'object-contain'
            } ${!isLoaded ? 'opacity-0' : 'opacity-100'} transition-opacity duration-200`}
            onLoad={handleImageLoad}
            onError={handleImageError}
          />
        )}
      </div>
      
      {category === 'meme_image' && itemName && (
        <div className="mt-1 text-xs text-gray-600 truncate">
          {itemName || `Meme ${index + 1}`}
        </div>
      )}
    </div>
  );
};

// Component for custom image upload
const CustomImageUpload = ({ handleImageUpload, uploadError }) => (
  <div className="space-y-2">
    <input
      type="file"
      accept="image/*"
      onChange={handleImageUpload}
      className="block w-full text-sm text-gray-500
        file:mr-4 file:py-2 file:px-4
        file:rounded-full file:border-0
        file:text-sm file:font-semibold
        file:bg-blue-50 file:text-blue-700
        hover:file:bg-blue-100"
    />
    {uploadError && (
      <p className="text-red-500 text-sm">{uploadError}</p>
    )}
  </div>
);

// Component for text inputs
const TextInputs = ({ selectedParts, handleTextChange }) => (
  <div className="space-y-2">
    <h3 className="text-lg font-semibold" style={{color: "white"}}>Text</h3>
    <textarea
      type="text"
      placeholder="Enter first text"
      value={selectedParts.text1.text}
      onChange={handleTextChange('text1')}
      className="w-full p-2 border border-gray-300 rounded mb-2"
      autoFocus={false}
      style={{ fontSize: '16px' }}  // Prevents iOS zoom
    />
    <textarea
      type="text"
      placeholder="Enter second text"
      value={selectedParts.text2.text}
      onChange={handleTextChange('text2')}
      className="w-full p-2 border border-gray-300 rounded"
      autoFocus={false}
      style={{ fontSize: '16px' }}  // Prevents iOS zoom
    />
  </div>
);

// Component for parts category
const PartsCategory = ({ 
  category, 
  items, 
  selectedParts, 
  handlePartSelection, 
  isLoadingMemes, 
  fetchMemes, 
  memes 
}) => (
  <div className="space-y-2">
    {category === 'meme_image' && (
      <div className="flex justify-end">
        <button
          onClick={fetchMemes}
          disabled={isLoadingMemes}
          className="flex items-center gap-2 px-3 py-1 bg-blue-500 text-white rounded hover:bg-blue-600 disabled:bg-gray-400"
        >
          <RefreshCcw size={16} className={isLoadingMemes ? 'animate-spin' : ''} />
          Refresh Memes
        </button>
      </div>
    )}
    <div className="grid grid-cols-3 md:grid-cols-6 gap-2 max-h-[calc(6*42px)] overflow-y-auto">
      {category === 'meme_image' && isLoadingMemes ? (
        <LoadingSpinner />
      ) : (
        items.map((item, index) => (
          <PartItem
            key={index}
            item={category === 'meme_image' ? item : item.split('.').slice(0, -1).join('.') + '_thumbnail.' + item.split('.').pop()}
            isSelected={selectedParts[category] === item}
            category={category}
            onClick={() => handlePartSelection(category, item)}
            itemName={category === 'meme_image' ? memes[index]?.name : undefined}
          />
        ))
      )}
    </div>
  </div>
);
  

// Main PartsSelection component
const PartsSelection = ({ 
  parts, 
  selectedParts, 
  handlePartSelection, 
  handleTextChange, 
  handleImageUpload, 
  uploadError, 
  isLoadingMemes, 
  fetchMemes, 
  memes 
}) => {
  // Add state for expanded categories
  const [expandedCategories, setExpandedCategories] = useState(
    Object.keys(parts).reduce((acc, category) => {
      acc[category] = true; // Initially all categories are expanded
      return acc;
    }, {})
  );

  // Toggle function for individual categories
  const toggleCategory = (category) => {
    setExpandedCategories(prev => ({
      ...prev,
      [category]: !prev[category]
    }));
  };

  // Toggle all categories
  const toggleAll = () => {
    const areAllExpanded = Object.values(expandedCategories).every(v => v);
    setExpandedCategories(
      Object.keys(parts).reduce((acc, category) => {
        acc[category] = !areAllExpanded;
        return acc;
      }, {})
    );
  };
  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <h2 className="text-xl font-semibold text-white">Select your parts</h2>
        <button
          onClick={toggleAll}
          className="px-3 py-1 bg-blue-600 text-white rounded-md hover:bg-blue-700 transition-colors"
        >
          {Object.values(expandedCategories).every(v => v) ? 'Collapse All' : 'Expand All'}
        </button>
      </div>
      
      {Object.entries(parts).map(([category, items]) => (
        <div key={category} className="border border-gray-700 rounded-lg overflow-hidden">
          <button
            onClick={() => toggleCategory(category)}
            className="w-full px-4 py-2 bg-gray-800 flex justify-between items-center hover:bg-gray-700 transition-colors"
          >
            <span className="font-medium text-white capitalize">
              {category.replace('_', ' ')}
            </span>
            <svg
              className={`w-5 h-5 text-white transform transition-transform ${
                expandedCategories[category] ? 'rotate-180' : ''
              }`}
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
            </svg>
          </button>

          <div
            className={`transition-all duration-300 ease-in-out ${
              expandedCategories[category]
                ? 'max-h-[500px] opacity-100'
                : 'max-h-0 opacity-0 overflow-hidden'
            }`}
          >
            <div className="p-4">
              <PartsCategory
                category={category}
                items={items}
                selectedParts={selectedParts}
                handlePartSelection={handlePartSelection}
                isLoadingMemes={isLoadingMemes}
                fetchMemes={fetchMemes}
                memes={memes}
              />
              {category === 'custom_image' && (
                <CustomImageUpload
                  handleImageUpload={handleImageUpload}
                  uploadError={uploadError}
                />
              )}
            </div>
          </div>
        </div>
      ))}

      <TextInputs
        selectedParts={selectedParts}
        handleTextChange={handleTextChange}
      />
    </div>
  );
};

export default PartsSelection;
