import React from 'react';

function PresetsBar({ presets, loadPreset }) {
  return (
    <div className="mb-6">
      <h3 className="text-lg font-semibold mb-2 text-white">Saved Presets</h3>
      <div className="flex flex-wrap gap-2">
        {presets.map(preset => (
          <div
            key={preset.id}
            className="group relative bg-gray-200 rounded-lg hover:bg-gray-300 transition-colors"
          >
            <button
              onClick={() => loadPreset(preset)}
              className="px-4 py-2 whitespace-nowrap"
            >
              {preset.name}
            </button>
          </div>
        ))}
      </div>
    </div>
  );
}

export default PresetsBar;
