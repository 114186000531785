import React, { useEffect, useRef, useState } from 'react';

const SceneLayer = ({ 
  imageSrc, 
  zIndex = 1,
  className = '',
  parallax = false,
  parallaxSpeed = 0,
}) => {
  const [offset, setOffset] = useState(0);
  const containerRef = useRef(null);

  useEffect(() => {
    if (!parallax || !containerRef.current) return;

    const handleScroll = () => {
      const rect = containerRef.current.getBoundingClientRect();
      const scrolled = window.scrollY;
      const speed = parallaxSpeed;
      
      // Calculate how far into view the element is
      const elementTop = rect.top + scrolled;
      const windowBottom = scrolled + window.innerHeight;
      const visibleAmount = windowBottom - elementTop;
      
      // Only apply parallax when element is in view
      if (visibleAmount > 0) {
        // Start from 100% below and move up to final position
        const startOffset = window.innerHeight; // Start fully below viewport
        const movement = Math.min(visibleAmount * speed, startOffset);
        setOffset(startOffset - movement);
      }
    };

    window.addEventListener('scroll', handleScroll);
    // Initial calculation
    handleScroll();
    
    return () => window.removeEventListener('scroll', handleScroll);
  }, [parallax, parallaxSpeed]);

  const baseStyle = {
    position: zIndex === 3 ? 'relative' : 'absolute',
    width: '100%',
    height: 'auto',
    zIndex,
    transition: parallax ? 'transform 0.1s ease-out' : undefined,
  };

  // Only add positioning if not overridden by className
  if (!className.includes('!left-') && !className.includes('!right-')) {
    baseStyle.left = zIndex === 3 ? 'auto' : '0';
  }
  if (!className.includes('!top-')) {
    baseStyle.top = zIndex === 3 ? 'auto' : '0';
  }

  return (
    <div 
      ref={containerRef}
      className={zIndex === 3 ? 'relative w-full' : 'absolute inset-0'}
    >
      <img 
        src={imageSrc}
        alt=""
        className={`w-full h-auto transform ${className}`}
        style={{
          ...baseStyle,
          '--parallax-y': `${offset}px`,
          transform: parallax ? `translateY(var(--parallax-y))` : undefined
        }}
      />
    </div>
  );
};

export default SceneLayer;
