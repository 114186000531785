import React, { useRef, useState, useEffect } from 'react';
import CharacterCustomizer from './components/CharacterCustomizer';
import ScrollSequence from './components/ScrollSequence';
import Footer from './components/Footer';
import LandingHeader from './components/Header';
import NavBar from './components/NavBar';
import GradientSection from './components/GradientSection';
import ParallaxObject from './components/parallax/ParallaxObject';
import { scrollToSection } from './utils/scroll';
import Fields from './components/Fields';
import SunGradientSection from './components/SunGradientSection';
import MeetRugman from './components/MeetRugman';
import GeneratorGuide from './components/GeneratorGuide';
import Friends from './components/HyperFriends';
import { useIsMobile, getImagePath } from './utils/imageUtils';
import './styles.css';
import './cursors.css';

function App() {
  const topRef = useRef(null);
  const afterScrollingSequenceRef = useRef(null);
  const fieldsRef = useRef(null);
  const customizerRef = useRef(null);
  const partnersRef = useRef(null);
  const footerRef = useRef(null);
  const [isPortrait, setIsPortrait] = useState(window.innerHeight > window.innerWidth);
  const [isMobileLandscape, setIsMobileLandscape] = useState(
    window.innerHeight <= 900 && window.innerWidth <= 1000 && window.innerHeight < window.innerWidth
  );
  const isDeviceMobile = useIsMobile();

  useEffect(() => {
    const handleResize = () => {
      setIsPortrait(window.innerHeight > window.innerWidth);
      setIsMobileLandscape(
        window.innerHeight <= 900 && window.innerWidth <= 1000 && window.innerHeight < window.innerWidth
      );
    };

    window.addEventListener('resize', handleResize);
    window.addEventListener('orientationchange', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('orientationchange', handleResize);
    };
  }, []);

  const navLinks = [
    { name: "Top", onClick: () => scrollToSection(topRef, afterScrollingSequenceRef) },
    { name: 'Trade', onClick: () => window.open('https://app.hyperliquid.xyz/trade/0x4978f3f49f30776d9d7397b873223c2d', '_blank') },
    { name: "About", onClick: () => scrollToSection(afterScrollingSequenceRef, afterScrollingSequenceRef) },
    { name: "Generate", onClick: () => scrollToSection(customizerRef, afterScrollingSequenceRef) },
    { name: "Partners", onClick: () => scrollToSection(partnersRef, afterScrollingSequenceRef) },
  ];

  const getPositionY = (portraitPos, desktopPos, mobileLandscapePos) => {
    if (isPortrait) return portraitPos;
    return isMobileLandscape ? mobileLandscapePos : desktopPos;
  };

  const getParallaxSpeed = (portraitSpeed, desktopSpeed, mobileLandscapeSpeed) => {
    if (isPortrait) return portraitSpeed;
    return isMobileLandscape ? mobileLandscapeSpeed : desktopSpeed;
  };

  const flyingRugmanPath = getImagePath('non_part/FlyingRugman/Flying_rugman_base', isDeviceMobile);
  const foreground_cloud_1_path = getImagePath('non_part/FlyingRugman/foreground_cloud_1', isDeviceMobile);

  return (
    <div style={{ backgroundColor: "Black" }} className="min-h-screen flex flex-col">
      <div className="fixed z-[999]">
        <NavBar links={navLinks} />
      </div>

      <GradientSection from="#000000">
        <LandingHeader ref={topRef} scrollToAfterScrollingSequence={() => scrollToSection(afterScrollingSequenceRef, afterScrollingSequenceRef)} />
      </GradientSection>

      <SunGradientSection />

      <GradientSection from="#000000" to="#99C5FE">
        <div className="relative w-full">
          <div className="w-full relative">
            <div className="relative">
              <div className="relative z-10">
                <ScrollSequence scrollHeight={isDeviceMobile ? 6000 : 8000} />
              </div>
            </div>
          </div>
        </div>
        <div className="h-[0vh]"></div>
      </GradientSection>

      <GradientSection from="#99C5FE" to="#85D0E0" className="min-h-screen lg:h-[150vh] sm:h-[100vh] max-w-screen overflow-x-clip">
        <ParallaxObject
          imageSrc={foreground_cloud_1_path}
          positionX={0}
          positionY={getPositionY(-80, 0, -185)}
          parallaxSpeed={5}
          width={isPortrait ? "20vh" : "25vw"}
          className="opacity-100 transition-transform duration-300"
          style={{
            transform: `rotate(5deg)`,
          }}
          zIndex={50}
        />
        <ParallaxObject
          imageSrc={foreground_cloud_1_path}
          positionX={2}
          positionY={getPositionY(-40, -40, -120)}
          parallaxSpeed={5}
          width={isPortrait ? "20vh" : "25vw"}
          className="opacity-100 transition-transform duration-300"
          style={{
            transform: `rotate(-15deg)`,
          }}
          zIndex={50}
        />
        <ParallaxObject
          imageSrc={foreground_cloud_1_path}
          positionX={60}
          positionY={getPositionY(-60, -25, -150)}
          parallaxSpeed={5}
          width={isPortrait ? "25vh" : "25vw"}
          className="opacity-100 transition-transform duration-300"
          style={{
            transform: `rotate(5deg)`,
          }}
          zIndex={50}
        />
        <ParallaxObject
          imageSrc={flyingRugmanPath}
          positionX={50}
          positionY={getPositionY(52, 45, 85)}
          width={isPortrait ? "min(66vh, 600px)" : "min(50vh, 500px)"}
          height="auto"
          parallaxSpeed={getParallaxSpeed(-2.0, -2.0, -1.25)}
          className='opacity-100'
          style={{
            minHeight: isPortrait ? '66vw' : '25vw',
            aspectRatio: 'auto',
            pointerEvents: 'none'
          }}
          zIndex={3}
          scrollThreshold={0.0}
        />
        <ParallaxObject
          imageSrc={flyingRugmanPath}
          positionX={50}
          positionY={getPositionY(55, 48, 88)}
          width={isPortrait ? "min(66vh, 600px)" : "min(50vh, 500px)"}
          height="auto"
          parallaxSpeed={getParallaxSpeed(-1.5, -1.5, -1.0)}
          className='opacity-60'
          style={{
            minHeight: isPortrait ? '66vw' : '25vw',
            aspectRatio: 'auto',
            pointerEvents: 'none'
          }}
          zIndex={2}
          scrollThreshold={0.0}
        />
        <ParallaxObject
          imageSrc={flyingRugmanPath}
          positionX={50}
          positionY={getPositionY(60, 52, 93)}
          width={isPortrait ? "min(66vh, 600px)" : "min(50vh, 500px)"}
          height="auto"
          parallaxSpeed={getParallaxSpeed(-1.25, -1.25, -0.75)}
          className='opacity-50'
          style={{
            minHeight: isPortrait ? '66vw' : '25vw',
            aspectRatio: 'auto',
            pointerEvents: 'none'
          }}
          zIndex={1}
          scrollThreshold={0.0}
        />
      </GradientSection>

      <GradientSection from="#85D0E0" className="w-full relative">
        <div ref={afterScrollingSequenceRef}></div>
        <MeetRugman />
      </GradientSection>

      <GradientSection from="#85D0E0" className="w-full relative overflow-hidden">
        <div ref={fieldsRef}>
          <Fields></Fields>
        </div>
      </GradientSection>

      <GradientSection from="#1c212f" className="w-full relative overflow-hidden">
        <GeneratorGuide></GeneratorGuide>
      </GradientSection>

      <div className="w-full" style={{ backgroundColor: "#1c212f" }}>
        <CharacterCustomizer ref={customizerRef} />
      </div>

      <GradientSection ref={partnersRef} from="#1c212f" className="w-full relative overflow-hidden">
        <Friends></Friends>
      </GradientSection>

      <GradientSection from="#1c212f" className="w-full relative overflow-hidden">
        <Footer ref={footerRef} />
      </GradientSection>
    </div>
  );
}

export default App;
