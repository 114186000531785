// TransformControls.jsx
import React from 'react';
import { Move, Maximize, RotateCcw, Layers, Trash2 } from 'lucide-react';

const RangeControl = ({ label, icon: Icon, value, onChange, min, max, step }) => (
  <div className="space-y-2">
    <label className="flex items-center gap-2">
      {Icon && <Icon size={16} />} {label}
    </label>
    <input
      type="range"
      min={min}
      max={max}
      step={step}
      value={value}
      onChange={(e) => onChange(parseFloat(e.target.value))}
      className="w-full"
    />
  </div>
);

const PositionControl = ({ xValue, yValue, onXChange, onYChange }) => (
  <div className="space-y-2">
    <label className="flex items-center gap-2">
      <Move size={16} /> Position
    </label>
    <div className="grid grid-cols-2 gap-2">
      <div>
        <label className="text-sm text-gray-600">X</label>
        <input
          type="range"
          min="-1"
          max="1"
          step="0.01"
          value={xValue}
          onChange={(e) => onXChange(parseFloat(e.target.value))}
          className="w-full"
        />
      </div>
      <div>
        <label className="text-sm text-gray-600">Y</label>
        <input
          type="range"
          min="-1"
          max="1"
          step="0.01"
          value={yValue}
          onChange={(e) => onYChange(parseFloat(e.target.value))}
          className="w-full"
        />
      </div>
    </div>
  </div>
);

const LayerControl = ({ onBringForward, onSendBackward }) => (
  <div className="space-y-2">
    <label className="flex items-center gap-2">
      <Layers size={16} /> Layer Order
    </label>
    <div className="flex gap-2">
      <button
        onClick={onBringForward}
        className="px-2 py-1 bg-gray-100 rounded hover:bg-gray-200"
      >
        Bring Forward
      </button>
      <button
        onClick={onSendBackward}
        className="px-2 py-1 bg-gray-100 rounded hover:bg-gray-200"
      >
        Send Backward
      </button>
    </div>
  </div>
);

const TransformControls = ({ 
  selectedPartForEdit, 
  transforms, 
  updateTransform,
  handleDeletePart,
}) => {
  if (!selectedPartForEdit) return null;

  const currentTransform = transforms[selectedPartForEdit];

  return (
    <div className="mt-4 p-4 bg-white rounded-lg shadow">
      <div className="grid grid-cols-2 gap-2">
        <h3 className="text-lg font-semibold mb-4 capitalize">{selectedPartForEdit} Controls </h3>
        <div className="flex justify-end gap-2">
          <button
              onClick={() => handleDeletePart(selectedPartForEdit)}
              className="flex items-center gap-2 px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition-colors disabled:bg-gray-400 disabled:cursor-not-allowed"
            >
            <Trash2 size={20} />
          </button>
        </div>
      </div>
      
      <div className="space-y-4">
        <PositionControl
          xValue={currentTransform.x}
          yValue={currentTransform.y}
          onXChange={(value) => updateTransform('x', value)}
          onYChange={(value) => updateTransform('y', value)}
        />

        <RangeControl
          label="Opacity"
          value={currentTransform.opacity}
          onChange={(value) => updateTransform('opacity', value)}
          min="0"
          max="1"
          step="0.01"
        />

        <RangeControl
          label="Scale"
          icon={Maximize}
          value={currentTransform.scale}
          onChange={(value) => updateTransform('scale', value)}
          min="0.1"
          max="2"
          step="0.01"
        />

        <RangeControl
          label="Rotation"
          icon={RotateCcw}
          value={currentTransform.rotation}
          onChange={(value) => updateTransform('rotation', value)}
          min="-180"
          max="180"
          step="1"
        />

        <LayerControl
          onBringForward={() => updateTransform('zIndex', currentTransform.zIndex + 1)}
          onSendBackward={() => updateTransform('zIndex', currentTransform.zIndex - 1)}
        />
      </div>
    </div>
  );
};

export default TransformControls;