import React from 'react';

function DeleteConfirmModal({ setShowDeleteConfirm, clearAllPresets }) {
  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
      <div className="bg-white rounded-lg p-6 max-w-md w-full">
        <h3 className="text-lg font-semibold mb-4">Clear All Presets</h3>
        <p className="mb-6">Are you sure you want to clear all presets? This action cannot be undone.</p>
        <div className="flex justify-end gap-2">
          <button
            onClick={() => setShowDeleteConfirm(false)}
            className="px-4 py-2 bg-gray-200 rounded hover:bg-gray-300"
          >
            Cancel
          </button>
          <button
            onClick={() => {
              clearAllPresets();
              setShowDeleteConfirm(false);
            }}
            className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600"
          >
            Clear All
          </button>
        </div>
      </div>
    </div>
  );
}

export default DeleteConfirmModal;
