import React, { useState, useRef, useImperativeHandle, forwardRef, useEffect } from 'react';
import { motion } from 'framer-motion';
import { createPortal } from 'react-dom';

const ParticleSystem = forwardRef(({ is_mobile, enableMouseParticles = false, enableClickParticles = false }, ref) => {
  const [particles, setParticles] = useState([]);
  const [isInteracting, setIsInteracting] = useState(false);
  
  // Refs for tracking last spawn position and time
  const lastSpawnTimeRef = useRef(0);
  const lastSpawnPosRef = useRef({ x: 0, y: 0 });
  const lastClickTimeRef = useRef(0);  // New ref for tracking last click time
  const preloadedImages = useRef(new Array(36));
  
  const mobile_scale_factor = is_mobile ? 0.5 : 1;

  // Constants for throttling
  const MIN_SPAWN_INTERVAL = is_mobile ? 100 : 500;
  const MIN_DISTANCE = is_mobile ? 50 : 200;
  const CLICK_COOLDOWN = 300; // 300ms cooldown between clicks

  // Preload images on mount
  useEffect(() => {
    for (let i = 1; i <= 36; i++) {
      const img = new Image();
      img.src = `assets/face/face${i}.webp`;
      preloadedImages.current[i-1] = img.src;
    }
  }, []);

  const calculateDistance = (x1, y1, x2, y2) => {
    return Math.sqrt(Math.pow(x2 - x1, 2) + Math.pow(y2 - y1, 2));
  };

  const shouldSpawnParticle = (x, y) => {
    const currentTime = Date.now();
    const timeSinceLastSpawn = currentTime - lastSpawnTimeRef.current;
    const distanceFromLastSpawn = calculateDistance(
      lastSpawnPosRef.current.x,
      lastSpawnPosRef.current.y,
      x,
      y
    );

    return timeSinceLastSpawn >= MIN_SPAWN_INTERVAL || distanceFromLastSpawn >= MIN_DISTANCE;
  };

  const spawnParticle = (x, y) => {
    // Account for scroll position
    const scrollY = window.scrollY;
    const faceIndex = Math.floor(Math.random() * 36);
    const newParticle = {
      id: Date.now(),
      x,
      y: y - scrollY, // Add scroll offset to y position
      velocityX: (Math.random() - 0.5) * 5,
      rotation: Math.random() * 360,
      fallSpeed: 1 + Math.random() * 0.2,
      faceIndex
    };
    setParticles(prev => [...prev, newParticle]);

    // Update last spawn position and time
    lastSpawnTimeRef.current = Date.now();
    lastSpawnPosRef.current = { x, y };

    // Remove particle after animation
    setTimeout(() => {
      setParticles(prev => prev.filter(p => p.id !== newParticle.id));
    }, 2000);
  };

  const spawnExplosionParticles = (centerX, centerY) => {
    const numParticles = 20;
    const newParticles = Array.from({ length: numParticles }).map((_, i) => {
      const angle = (i / numParticles) * Math.PI * 2;
      const speed = mobile_scale_factor * (5 + Math.random() * 2.5);
      const faceIndex = Math.floor(Math.random() * 36);
      return {
        id: Date.now() + i,
        x: centerX,
        y: centerY,
        velocityX: Math.cos(angle) * speed,
        velocityY: Math.sin(angle) * speed,
        rotation: Math.random() * 360,
        scale: mobile_scale_factor * (7 + Math.random()*5),
        faceIndex
      };
    });
    
    setParticles(prev => [...prev, ...newParticles]);

    // Remove explosion particles after animation
    setTimeout(() => {
      setParticles(prev => prev.filter(p => !newParticles.includes(p)));
    }, 2000);
  };

  const handleInteraction = (e) => {
    if (!enableMouseParticles) return;
    
    e.preventDefault(); // Prevent default touch behaviors
    
    const rect = e.currentTarget.getBoundingClientRect();
    let x, y;

    // Handle both mouse and touch events
    if (e.type.startsWith('touch')) {
      const touch = e.touches[0] || e.changedTouches[0];
      x = touch.clientX - rect.left;
      y = touch.clientY - rect.top;
    } else {
      x = e.clientX - rect.left;
      y = e.clientY - rect.top;
    }

    if (isInteracting && shouldSpawnParticle(x, y)) {
      spawnParticle(x, y);
    }
  };

  const handleClick = (e) => {
    if (!enableClickParticles) return;
    
    e.preventDefault();
    const currentTime = Date.now();
    const timeSinceLastClick = currentTime - lastClickTimeRef.current;
    
    // Only process click if enough time has passed since last click
    if (timeSinceLastClick >= CLICK_COOLDOWN) {
      const rect = e.currentTarget.getBoundingClientRect();
      const x = e.clientX - rect.left;
      const y = e.clientY - rect.top;
      
      spawnParticle(x, y);
      lastClickTimeRef.current = currentTime;
    }
  };

  // Expose methods to parent through ref
  useImperativeHandle(ref, () => ({
    spawnExplosionParticles,
    handleInteraction,
    handleClick,
    setIsInteracting
  }));

  // Create portal for particles to render at root level
  return createPortal(
    <div className="fixed inset-0 pointer-events-none" style={{ zIndex: 1000 }}>
      {particles.map((particle) => (
        <motion.img
          key={particle.id}
          src={preloadedImages.current[particle.faceIndex]}
          className="absolute w-12 h-12"
          initial={{ 
            x: particle.x, 
            y: particle.y,
            scale: particle.scale || (10 * mobile_scale_factor),
            opacity: 1,
            rotate: particle.rotation
          }}
          animate={{ 
            x: particle.velocityX ? 
              particle.x + particle.velocityX * 100 :
              [particle.x, particle.x + particle.velocityX * 20, particle.x + particle.velocityX * 50],
            y: particle.velocityY ?
              particle.y + particle.velocityY * 100 :
              [particle.y, particle.y + 200, particle.y + 400],
            opacity: [1, 1, 0],
            scale: particle.scale ? 
              [particle.scale, particle.scale * 0.5, 0] :
              [10 * mobile_scale_factor, 5 * mobile_scale_factor, 1 * mobile_scale_factor],
            rotate: [particle.rotation, particle.rotation + (particle.velocityX > 0 ? 180 : -180)]
          }}
          transition={{ 
            duration: 2,
            ease: [0.2, 0.8, 0.4, 1],
            times: [0, 0.6, 1]
          }}
        />
      ))}
    </div>,
    document.body
  );
});

export default ParticleSystem;
