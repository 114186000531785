import React from 'react';
import { Type } from 'lucide-react';

const EnhancedTextControls = ({ selectedPartForEdit, textStyles, setTextStyles, fonts }) => {
  if (!selectedPartForEdit?.includes('text')) return null;

  return (
    <div className="mt-4 p-4 bg-white rounded-lg shadow">
      <h3 className="text-lg font-semibold mb-4">Text Style</h3>
      <div className="space-y-4">
        <div>
          <label className="flex items-center gap-2">
            <Type size={16} /> Font
          </label>
          <select
            value={textStyles[selectedPartForEdit].font}
            onChange={(e) => setTextStyles(prev => ({
              ...prev,
              [selectedPartForEdit]: {
                ...prev[selectedPartForEdit],
                font: e.target.value
              }
            }))}
            className="w-full p-2 border rounded mt-1"
          >
            {fonts.map(font => (
              <option key={font} value={font}>{font}</option>
            ))}
          </select>
        </div>

        <div>
          <label className="flex items-center gap-2">
            Color
          </label>
          <input
            type="color"
            value={textStyles[selectedPartForEdit].color}
            onChange={(e) => setTextStyles(prev => ({
              ...prev,
              [selectedPartForEdit]: {
                ...prev[selectedPartForEdit],
                color: e.target.value
              }
            }))}
            className="w-full h-10 p-1 rounded mt-1"
          />
        </div>

        <div className="flex gap-4">
          <label className="flex items-center gap-2">
            <input
              type="checkbox"
              checked={textStyles[selectedPartForEdit].hasShadow}
              onChange={(e) => setTextStyles(prev => ({
                ...prev,
                [selectedPartForEdit]: {
                  ...prev[selectedPartForEdit],
                  hasShadow: e.target.checked
                }
              }))}
            />
            Shadow
          </label>

          <label className="flex items-center gap-2">
            <input
              type="checkbox"
              checked={textStyles[selectedPartForEdit].hasOutline}
              onChange={(e) => setTextStyles(prev => ({
                ...prev,
                [selectedPartForEdit]: {
                  ...prev[selectedPartForEdit],
                  hasOutline: e.target.checked
                }
              }))}
            />
            Outline
          </label>
        </div>
      </div>
    </div>
  );
};

export default EnhancedTextControls;
