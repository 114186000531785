import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import SVGLogo from './SVGLogo';
import SVGSocials from './SVGSocials';

const Logo = ({ isImageLoaded, particleSystemRef }) => {
  const [clickCount, setClickCount] = useState(0);
  const [isLogoVisible, setIsLogoVisible] = useState(true);
  const logoRectRef = React.useRef(null);

  const handleLogoClick = () => {
    const newCount = clickCount + 1;
    setClickCount(newCount);
    
    if (newCount === 6) {
      setIsLogoVisible(false);
      
      // Use the ref instead of event.currentTarget
      const rect = logoRectRef.current.getBoundingClientRect();
      const centerX = rect.left + rect.width / 2;
      const centerY = rect.top + rect.height / 2;
      particleSystemRef.current?.spawnExplosionParticles(centerX, centerY);
      
      setTimeout(() => {
        setClickCount(0);
        setIsLogoVisible(true);
      }, 2000);
    }
  };

  const handleRugmanOnHLClick = () => {
    //window.open('', '_blank');
  };

  return (
    <AnimatePresence>
      {isLogoVisible && (
        <motion.div
          className="absolute inset-0 flex items-center justify-center overflow-visible"
          initial={{ opacity: 0, y: 100 }}
          animate={{ 
            opacity: isImageLoaded ? 1 : 0, 
            y: isImageLoaded ? 0 : 100 
          }}
          exit={{ opacity: 0, scale: 0 }}
          transition={{
            opacity: {
              duration: 3.0,
              delay: 1.5,
              ease: [0.19, 1.0, 0.22, 1.0]
            },
            y: {
              duration: 3.0,
              delay: 1.5,
              ease: [0.19, 1.0, 0.22, 1.0]
            }
          }}
        >
          <div className="relative z-10 w-full max-w-lg px-4 overflow-visible">
            <motion.div
              ref={logoRectRef}
              className="w-full h-auto cursor-pointer overflow-visible"
            >
              <SVGLogo 
                onRugmanOnHLClick={handleRugmanOnHLClick}
                onClick={handleLogoClick}
                handleLogoClick={handleLogoClick}
              />
              <SVGSocials  viewBox="0 150 400 70" 
              />
            </motion.div>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default Logo;
