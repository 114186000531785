import React from 'react';
import { Sparkles, Undo, Save, Trash2 } from 'lucide-react';

function QuickActionsBar({ randomize, handleUndo, currentIndex, initiatePresetSave, setShowDeleteConfirm, presets }) {
  return (
    <div className="mb-6 flex flex-wrap gap-4">
      <button
        onClick={randomize}
        className="flex clickable items-center gap-2 px-4 py-2 bg-purple-500 text-white rounded-lg hover:bg-purple-600 transition-colors"
      >
        <Sparkles size={20} className='clickable' />
        Randomize
      </button>
      <button
        onClick={handleUndo}
        disabled={currentIndex <= 0}
        className="flex clickable items-center gap-2 px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition-colors disabled:bg-gray-400 disabled:cursor-not-allowed"
      >
        <Undo size={20} className='clickable'/>
        Undo
      </button>
      <button
        onClick={initiatePresetSave}
        className="flex clickable items-center gap-2 px-4 py-2 bg-green-500 text-white rounded-lg hover:bg-green-600 transition-colors"
      >
        <Save size={20} className='clickable' />
        Save Preset
      </button>
      {presets.length > 0 && (
        <button
          onClick={() => setShowDeleteConfirm(true)}
          className="flex clickable items-center gap-2 px-4 py-2 bg-red-500 text-white rounded-lg hover:bg-red-600 transition-colors"
        >
          <Trash2 size={20} />
          Clear All Presets
        </button>
      )}
    </div>
  );
}

export default QuickActionsBar;
